import SpinnerAnimation from 'components/Spinners/SpinnerAnimation/SpinnerAnimation';
import SquareLogoAnimate from 'components/SquareLogoAnimate/SquareLogoAnimate';
import appContext from 'context/app-context';
import React, { useContext, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styles from './styles.module.css';

export default function TermsAndPrivacyConfirmation() {
  const { setContinuePage, getTransaction, getDocuments, getSignatory } =
    useContext(appContext);
  const [showLoader, setShowLoader] = useState(false);
  const navigate = useNavigate();
  const [userType, setUserType] = useState('new');

  const welcomeMessages = () => {
    return (
      <>
        <div className='text-center'>
          <p className='pb-4 fw-400 fs-14'>
            Are you signing as an individual, or
            <br />
            sending files as a business?
          </p>
        </div>
      </>
    );
  };
  const confirmIndividual = () => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    let transactionUuid = params.get('transaction');
    let signatory = params.get('signatory') ?? '';
    let self = params.get('self');
    let signType = params.get('sign_type');
    setUserType(signType);
    getTransaction(transactionUuid);
    getDocuments(transactionUuid, self, signatory);
    getSignatory(transactionUuid, self, signatory);

    navigate({
      pathname: '/continue',
      search: `?${createSearchParams(
        'transaction=' +
          transactionUuid +
          '&sign_type=' +
          signType +
          '&signatory=' +
          signatory +
          '&self=' +
          self
      )}`
    });
    setContinuePage(5);
  };
  const confirmBusiness = () => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    let transactionUuid = params.get('transaction');
    let signatory = params.get('signatory') ?? '';
    let self = params.get('self');
    let signType = params.get('sign_type');
    setUserType(signType);
    getTransaction(transactionUuid);
    getDocuments(transactionUuid, self, signatory);
    getSignatory(transactionUuid, self, signatory);

    navigate({
      pathname: '/continue',
      search: `?${createSearchParams(
        'transaction=' +
          transactionUuid +
          '&sign_type=' +
          signType +
          '&signatory=' +
          signatory +
          '&self=' +
          self
      )}`
    });
    setContinuePage(6);
  };

  return (
    <>
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page'
        >
          <div className='mt-5'>
            <SquareLogoAnimate />
          </div>
          <div className={styles.bringContentDown}>
            {welcomeMessages()}
            <Form>
              <Form.Group className='mb-3 ps-3 pe-3'>
                <div>
                  {showLoader ? (
                    <SpinnerAnimation />
                  ) : (
                    <div className='text-center'>
                      <Button
                        variant='dark'
                        className='btn-rounded btn-block blackButton'
                        onClick={confirmIndividual}
                      >
                        <span className='fs-14 fw-700'>INDIVIDUAL</span>
                      </Button>
                    </div>
                  )}
                </div>
                <br />
                <div className='text-center'>
                  <Button
                    className='btn-rounded btn-block whiteButtonWithBorder'
                    onClick={confirmBusiness}
                  >
                    <span className='fs-14 fw-700' style={{ color: 'black' }}>
                      BUSINESS
                    </span>
                  </Button>
                </div>
              </Form.Group>
              <div style={{ marginTop: '50px', textAlign: 'center' }}>
                <img
                  src='/static/media/dewly-logo.95b074ed119097ec1853a1fdc0320f6b.svg'
                  alt='dewly'
                />
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
}
