import { Viewer, Worker } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import * as Sentry from '@sentry/react';
import downloadIcon from 'assets/images/download-white.svg';
import LogoBlackIcon from 'assets/images/logo-black.svg';
import ViewAcceptAcknowledgements from 'components/Acknowledgements/ViewAcceptAcknowledgements';
import SigningHeader from 'components/Pages/Signing/Header/Header';
import appContext from 'context/app-context';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import packageJson from '../../../../../package.json';
import styles from './styles.module.css';

// PDF styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

export default function DocumentView() {
  const {
    setCurrentPage,
    currentDocument,
    transaction,
    user,
    unSignedDocuments,
    documents,
    signatory
  } = useContext(appContext);

  const [userType, setUserType] = useState('new');
  const navigate = useNavigate();
  //TODO: use correct transaction uuid
  const documentUrl = `${process.env.REACT_APP_BASE_URL}transactions/${transaction?.uuid}/documents/${currentDocument?.uuid}/view`;
  const downloadUrl = `${process.env.REACT_APP_BASE_PATH}documents/${currentDocument?.uuid}/download`;
  // ACKNOWLEDGEMENTS
  const [userAcknowledgemets, setUserAcknowledgemets] = React.useState([]);
  const [showAcknowledgement, setShowAcknowledgement] = useState(false);
  const [signAllDocs, setSignAllDocs] = useState(false);
  useEffect(() => {
    if (signAllDocs && userAcknowledgemets?.length == 0) {
      navigate('/');
    }
  }, [userAcknowledgemets]);

  const pdfjsVersion = packageJson.dependencies['pdfjs-dist'];
  const zoomPluginInstance = zoomPlugin();
  const { Zoom } = zoomPluginInstance;

  function previousPage() {
    setCurrentPage(1);
  }

  useEffect(() => {
    sessionStorage.removeItem('selfie_verified');
  }, []);

  useEffect(() => {
    console.log('unsigned docs', unSignedDocuments);
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    let signType = params.get('sign_type');
    if (sessionStorage.getItem('token') && signType !== 'new') {
      setUserType('logged');
    } else {
      setUserType(signType);
    }
  }, [unSignedDocuments]);

  const signDocument = async () => {
    setSignAllDocs(true);

    if (currentDocument?.signed_at && userAcknowledgemets.length > 0) {
      setShowAcknowledgement(true);
      return;
    }

    if (user && userType !== 'new') {
      if (!user?.verified && unSignedDocuments === documents.length) {
        //ID verification
        setCurrentPage(3);
      } else {
        signFiles();
      }
    } else {
      //TODO: make sure the camera doesn't show on the second signe
      if (unSignedDocuments === documents.length) {
        //ID verification
        setCurrentPage(3);
      } else {
        signFiles();
      }
    }
  };

  // eslint-disable-next-line no-unused-vars
  function signFiles() {
    const selfieVerified = sessionStorage.getItem('selfie_verified');

    if (!selfieVerified) {
      //Selfie verification
      setCurrentPage(4);
    } else if (unSignedDocuments > 1) {
      //multiple files signing
      setCurrentPage(6);
    } else {
      //single file signing
      setCurrentPage(4);
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Row className='m0 bg-lightgray no-horizontal-scroll'>
        <SigningHeader previousPage={previousPage} />
        <Col
          xs={{ span: 12 }}
          lg={{ span: 8, offset: 2 }}
          className='min-height-full-page'
        >
          <div className={`pdf-viewer ${styles.main}`}>
            <div
              className='fileViewerZoomButton'
              style={{
                position: 'fixed',
                right: '20px',
                backgroundColor: '#fff',
                borderRadius: '6px',
                zIndex: '1'
              }}
            >
              <Zoom levels={[0.4, 0.8, 1, 1.2, 1.5, 2.4, 3.2]} />
            </div>
            <Worker
              workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}
            >
              <div className={styles.docViewer}>
                <Viewer
                  className='border-radius-6'
                  fileUrl={documentUrl}
                  plugins={[zoomPluginInstance]}
                />
              </div>
            </Worker>
            {!currentDocument?.signed_at ? (
              <div
                className={`${styles.btnSign}`}
                onClick={(e) => signDocument()}
              >
                <motion.div
                  animate={{
                    x: [0, -20, -20, -20],
                    opacity: [0, 1, 1, 1, 1, 1]
                  }}
                  transition={{
                    type: 'tween',
                    delay: 1.5,
                    duration: 4
                  }}
                  className={styles.signHereText}
                >
                  Sign here
                </motion.div>
                <img
                  src={LogoBlackIcon}
                  alt='sign'
                  className={` pulse-animate btn-rounded ${styles.signBtn}`}
                />
              </div>
            ) : (
              <div className={`pulse-animate ${styles.btnDownload}`}>
                <a
                  href={downloadUrl}
                  download={currentDocument.path}
                  target='_blank'
                  rel='noreferrer'
                >
                  <img
                    src={downloadIcon}
                    alt='sign'
                    className={` btn-rounded ${styles.signBtn}`}
                  />
                </a>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </motion.div>
  );
}
