import React, { useState, useEffect } from "react";
import styles from './styles.module.css';

const CarouselSlider = ({ children }) => {
  const [counter, setCounter] = useState(1);
  const [pause, setPause] = useState(false);
  const content = children;

  const handleNext = () => {
    if (counter !== content.length) {
      setCounter(counter + 1);
    } else {
      setCounter(1);
    }
  };

  const handlePre = () => {
    if (counter !== 1) {
      setCounter(counter - 1);
    } else {
      setCounter(content.length);
    }
  };

  const handlePage = page => {
    setCounter(page);
  };

  const handleMouse = () => {
    setPause(!pause);
  };

  useEffect(() => {
    let interval = setInterval(() => {
      if (!pause) {
        handleNext();
      } else {
        clearInterval(interval);
      }
    }, 5000);
    return () => clearInterval(interval);
  });

  return (
    <>
    <div
        className={styles.slide}
      >
        {content.map((item, index) => (
          <div
            className={counter - 1 === index ? `${styles.show}` :  `${styles.noShow}`}
            key={index}
          >
            {item}
          </div>
        ))}
      </div>

      <div className="text-center">
        {content.map((item, index) => (
          <span
            key={index}
            className={counter - 1 === index ? `${styles.activeDot}` : `${styles.dot}`}
            onClick={() => handlePage(index + 1)}
          />
        ))}
      </div>
    </>
  );
};

export default CarouselSlider;