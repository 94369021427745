import { ChevronRightIcon } from 'components/Icons/Icons';
import React from 'react';
import { Card } from 'react-bootstrap';
import styles from './styles.module.css';

export default function draftTransactions({ transactions }) {
  const openTransaction = async (transaction) => {
    window.location.href = `/transactions?transaction=${transaction.uuid}`;
  };

  return (
    <div className='pe-3 ps-3 pb-5'>
      <div className='text-center'>
        <div className={styles.transactionsPending}>
          <p className={styles.transactionsPendingAmount}>
            {transactions.length}
          </p>
        </div>
        <p className='fw-800 fs-18 mt-3'>Draft transactions</p>
      </div>

      {transactions.map((transaction, index) => {
        return (
          <Card
            key={index}
            className='mb-2 cardWhite pointer'
            onClick={(e) => openTransaction(transaction)}
          >
            <Card.Body className={styles.cardBody}>
              <Card.Text className={styles.cardBodyText} as='h6'>
                <div>
                  <p className='m0 fw-700 fs-14'>{transaction?.name}</p>
                </div>
                <span className='color-lightgray fs-12 fw-700'>
                  Completed by {transaction?.signed}/{transaction?.total}{' '}
                  signers
                </span>
              </Card.Text>
              <div className={styles.viewFileBtn}>
                <ChevronRightIcon />
              </div>
            </Card.Body>
          </Card>
        );
      })}
    </div>
  );
}
