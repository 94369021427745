import * as Sentry from '@sentry/react';
import ChevronLeftIcon from 'assets/images/chevron-left.svg';
import home from 'assets/images/home.svg';
import axios from 'axios';
import SpinnerAnimation from 'components/Spinners/SpinnerAnimation/SpinnerAnimation';
import appContext from 'context/app-context';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';

// import { PhoneInput } from 'components/PhoneInput';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';

export default function AddSignee(props) {
  const [showNewField, setShowNewField] = useState(false);
  const { setSendingPage, currentTransaction } = useContext(appContext);
  const [name, setName] = useState('');
  const [surname, setSurname] = useState('');
  const [email, setEmail] = useState('');
  const [cell, setCell] = useState('');
  const [cellError, setCellError] = useState();

  const [is_contact, setIsContact] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [errors, setErrors] = useState(null);
  const [errorResponse, setErrorResponse] = useState(null);
  const [customField, setCustomField] = useState('');
  const [customFields, setCustomFields] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    let fieldsArray = sessionStorage.getItem('customFields');
    if (fieldsArray) {
      setCustomFields(JSON.parse(fieldsArray));
    }
  }, []);

  const addSignee = async () => {
    setErrors(null);
    if (cell) {
      if (isValidPhoneNumber(cell)) {
        setCellError('');
      } else {
        setCellError('Invalid phone number.');
        window.scrollTo(0, 0);
        return;
      }
    }
    try {
      setShowLoader(true);
      const { data } = await axios.post(
        `transactions/${currentTransaction.id}/signatories/new`,
        {
          first_name: name,
          last_name: surname,
          cell: cell ? cell.replace('+', '') : cell,
          email: email,
          frequent: is_contact,
          country_code: '',
          custom_fields: customFields
        }
      );

      if (data.success) {
        setShowLoader(false);
        setSendingPage(2);
        window.scrollTo(0, 0);
        sessionStorage.removeItem('customFields');
      }
    } catch (e) {
      if (e.response?.status === 422) {
        setErrors(e.response.data.errors);
      }
      setShowLoader(false);
      window.scrollTo(0, 0);
      console.log(e);
      setErrorResponse(e.response?.data?.message);
      Sentry.captureException(e);
    }
  };

  async function addCustomField() {
    let customFieldsArray = customFields;
    customFieldsArray.push({ name: customField, value: '' });
    setCustomFields(customFieldsArray);
    sessionStorage.setItem('customFields', JSON.stringify(customFieldsArray));
    setCustomField('');
    setShowNewField(false);
  }

  async function updateCustomFields(name, value) {
    let newFieldsArray = customFields.map((field) => {
      if (field.name === name) {
        field.value = value;
      }
      return field;
    });
    setCustomFields(newFieldsArray);
    sessionStorage.setItem('customFields', JSON.stringify(newFieldsArray));
  }

  const checkUser = async (email, cell) => {
    cell = cell ? cell.replace('+', '') : '';
    setEmail(email);
    try {
      setShowLoader(false);
      const { data } = await axios.get(
        `users/check?email=${email}&cell=${cell}`
      );

      if (data.success) {
        if (email === data.user.email || cell === data.user.cell) {
          setName(data.user.first_name);
          setSurname(data.user.last_name);
        } else {
          setName('');
          setSurname('');
        }
      } else {
        setName('');
        setSurname('');
      }

      setCellError('');
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div className={styles.header}>
        <img
          src={ChevronLeftIcon}
          alt='go back'
          onClick={(e) => setSendingPage(2)}
          className={styles.goBack}
        />
        <span className='fw-700 fs-16'>Add someone</span>
        <img
          src={home}
          alt='home'
          className={styles.homeBtn}
          onClick={() => navigate('/')}
        />
      </div>
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page'
        >
          <Form className={`bg-white ${styles.form}`}>
            <div className='text-center pt-5 pb-3'>
              <p className='fs-16 fw-700 mb-0'>Signer Details</p>
            </div>
            <Form.Group className='mb-4 ps-3 pe-3'>
              <div className='pb-2 ps-4'>
                <label className={styles.label}>MOBILE NUMBER*</label>
              </div>
              <PhoneInput
                className='phoneInput phone-number-input'
                defaultCountry='ZA'
                value={cell}
                onChange={(e) => (setCell(e), checkUser(email, e))}
              />
              {cellError ? (
                <small className={styles.error}>{cellError}</small>
              ) : (
                ''
              )}
            </Form.Group>
            <Form.Group className='mb-4 ps-3 pe-3'>
              <div className='pb-2 ps-4'>
                <label className={styles.label}>EMAIL*</label>
              </div>
              <Form.Control
                type='email'
                onChange={(e) => checkUser(e.currentTarget.value, cell)}
                className={styles.input}
              />
              {errors?.email ? (
                <small className={styles.error}>{errors?.email}</small>
              ) : (
                ''
              )}
            </Form.Group>
            {customFields.length
              ? customFields.map((customField) => {
                  return (
                    <Form.Group className='mb-4 ps-3 pe-3'>
                      <div className='pb-2 ps-4'>
                        <label className={styles.label}>
                          {customField.name.toUpperCase()}
                        </label>
                      </div>
                      <Form.Control
                        type='text'
                        onChange={(e) =>
                          updateCustomFields(
                            customField.name,
                            e.currentTarget.value
                          )
                        }
                        className={styles.input}
                      />
                    </Form.Group>
                  );
                })
              : ''}

            <Form.Group className='mb-4 ps-3 pe-3'>
              <div className='pb-2 ps-4'>
                <label className={styles.label}>SAVE AS FREQUENT SIGNER</label>
              </div>
              <label className='react-switch ml-0 w-100'>
                <input
                  onChange={(e) => setIsContact(e.target.checked)}
                  className='react-switch-checkbox'
                  type='checkbox'
                />
                <div className='react-switch-button' />
                <div className='react-switch-labels'>
                  <span>No</span>
                  <span>Yes</span>
                </div>
              </label>
            </Form.Group>

            {errorResponse && (
              <small className={styles.error}>{errorResponse}</small>
            )}

            <div className='p-b-100' />

            {/* <div className='text-center pt-3 p-b-100'>
              <img
                src={PlusCircle}
                alt='add field'
                className={styles.plusCircle}
                onClick={(e) => setShowNewField(true)}
              />
              <p className='color-gray mt-2 mb-4 fs-12'>Add more info</p>
            </div> */}
            <div className={styles.footer}>
              <Row className='m0'>
                <Col
                  lg={{ span: 4, offset: 4 }}
                  md={{ span: 6, offset: 3 }}
                  className={styles.footerInner}
                >
                  {showLoader ? (
                    <SpinnerAnimation />
                  ) : (
                    <Button
                      variant='dark'
                      className='btn-block btn-rounded fs-16 fw-700'
                      onClick={(e) => addSignee()}
                    >
                      Add
                    </Button>
                  )}
                </Col>
              </Row>
            </div>

            {showNewField && <div className={styles.spacer}></div>}

            {showNewField && (
              <div className={styles.footerAnimate}>
                <Row className='m0'>
                  <Col
                    lg={{ span: 4, offset: 4 }}
                    md={{ span: 6, offset: 3 }}
                    className={styles.addSignee}
                  >
                    <p>
                      Enter a custom label for
                      <br />
                      the new field.
                    </p>
                    <Form.Group className='mb-4'>
                      <Form.Control
                        type='text'
                        onChange={(e) => setCustomField(e.currentTarget.value)}
                        className={styles.input}
                      />
                    </Form.Group>
                    <Button
                      variant='dark'
                      onClick={(e) => addCustomField(e)}
                      className='btn-block btn-rounded fw-700'
                    >
                      Add field
                    </Button>
                    <p className='m0 mt-4'>
                      <span
                        className='pointer fw-700'
                        onClick={(e) => setShowNewField(false)}
                      >
                        Cancel
                      </span>
                    </p>
                  </Col>
                </Row>
              </div>
            )}
          </Form>
        </Col>
      </Row>
    </motion.div>
  );
}
