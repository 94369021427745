import { useContext, useEffect, useState } from 'react';
import appContext from 'context/app-context';
import BillingPage from 'components/Pages/Billing/BillingPage/BillingPage';
import CardDetailsPage from 'components/Pages/Billing/CardDetailsPage/CardDetailsPage';
import { useNavigate } from 'react-router-dom';
import ViewInvoice from 'components/Pages/Billing/ViewInvoice/ViewInvoice';

export const Billing = () => {
  const { billingPage, getCard, getBilling, getUser } = useContext(appContext);
  const [page, setPage] = useState(billingPage);
  const navigate = useNavigate();

  useEffect(() => {
    if (!sessionStorage.getItem('token')) {
      navigate('/continue');
    }
    getUser();
    getCard();
    selectPage(billingPage);
    // getBilling();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billingPage]);

  const billingScreens = () => {
    switch (page) {
      case 0:
        return <BillingPage />;
      case 1:
        return <CardDetailsPage />;
      case 2:
        return <ViewInvoice />;
      default:
        return <BillingPage />;
    }
  };

  const selectPage = (currentPage) => {
    setPage(currentPage);
    window.scrollTo(0, 0);
  };

  return (
    <div id='outer-container'>
      <div id='page-wrap'>{billingScreens()}</div>
    </div>
  );
};
