import * as Sentry from '@sentry/react';
import cameraIcon from 'assets/images/camera.svg';
import axios from 'axios';
import GoBackHeader from 'components/GoBackHeader/GoBackHeader';
// import { PhoneInput } from 'components/PhoneInput';
import SpinnerAnimation from 'components/Spinners/SpinnerAnimation/SpinnerAnimation';
import appContext from 'context/app-context';
import { motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useFileUpload } from 'use-file-upload';
import styles from './styles.module.css';

const TITLES = ['Mr', 'Miss', 'Mrs', 'Ms', 'Sir'];

export default function MyDetails() {
  const { setProfilePage, user, setUser, countries } = useContext(appContext);
  const [name, setFirstName] = useState(user?.first_name);
  const [surname, setSurame] = useState(user?.last_name);
  const [email, setEmail] = useState(user?.email);
  const [id_number, setIdNumber] = useState(user?.id_number);
  const [title, setTitle] = useState(user?.title);
  const [country, setCountry] = useState(user?.country);
  const [cell, setCell] = useState('+' + user.cell);
  const [role, setRole] = useState(user?.role);
  const [errors, SetErrors] = useState(null);
  const [showLogoLoader, setShowLogoLoader] = useState(false);
  const [logo, setLogo] = useState(null);
  const [companyLogo, selectCompanyLogo] = useFileUpload();
  const [companyDetails, setCompanyDetails] = useState([]);

  // Form data
  const [telephone, setTelephone] = useState(user?.telephone);
  const [tax_no, setTaxNo] = useState(user?.tax_no);
  const [address_building_unit_no, setAddressBuildingUnitNo] = useState(
    user?.address_building_unit_no
  );
  const [address_street_name, setAddressStreetName] = useState(
    user?.address_street_name
  );
  const [address_suburb, setSetAddresssuburb] = useState(user?.address_suburb);
  const [address_city, setAddressCity] = useState(user?.address_city);
  const [address_postal_code, setAddressPostalCode] = useState(
    user?.address_postal_code
  );
  const [bank_name, setBankName] = useState(user?.bank_name);
  const [bank_branch_name, setBankBranchName] = useState(
    user?.bank_branch_name
  );
  const [bank_branch_code, setBankBranchCode] = useState(
    user?.bank_branch_code
  );
  const [bank_account_no, setBankAccountNo] = useState(user?.bank_account_no);
  const [bank_account_holder, setBankAccountHolderName] = useState(
    user?.bank_account_holder
  );
  const [occupation, setOccupation] = useState(user?.occupation);
  const [on_behalf_of, setOnBehalfOf] = useState(user?.on_behalf_of);
  const [position_title, setPositionTitle] = useState(user?.position_title);
  // const [company_name, setCompanyName] = useState(user?.company_name);

  // Company Form data
  const [company_name, setCompanyName] = useState(companyDetails?.name);
  const [trading_name, setTradingName] = useState(companyDetails?.trading_name);
  const [companyRegNo, setCompanyRegNo] = useState(
    companyDetails?.registration
  );
  const [vat_no, setCompanyVatNo] = useState(companyDetails?.vat_no);
  const [nature_of_business, setNatureOfBusiness] = useState(
    companyDetails?.nature_of_business
  );
  const [industry, setIndustry] = useState(companyDetails?.industry);
  const [financial_year_end, setCompanyFinancialYear] = useState(
    companyDetails?.financial_year_end
  );
  const [contact_person, setCompanyContactPerson] = useState(
    companyDetails?.contact_person
  );
  const [company_contact_no, setCompanyContactNo] = useState(
    companyDetails?.cell
  );
  const [company_email, setCompanyEmail] = useState(companyDetails?.email);
  const [company_bank_name, setBusinessBankName] = useState(
    companyDetails?.bank_name
  );
  const [company_bank_branch_name, setBusinessBankBranchName] = useState(
    companyDetails?.bank_branch_name
  );
  const [company_bank_branch_code, setBusinessBankBranchCode] = useState(
    companyDetails?.bank_branch_code
  );
  const [company_bank_account_no, setBusinessBankAccNo] = useState(
    companyDetails?.bank_account_no
  );

  const [company_building, setBusinessAddressBuilding] = useState(
    companyDetails?.building
  );
  const [company_street_name, setBusinessAddressStreeName] = useState(
    companyDetails?.street_name
  );
  const [company_suburb, setBusinessAddresssuburb] = useState(
    companyDetails?.suburb
  );
  const [company_city, setBusinessAddressCity] = useState(companyDetails?.city);
  const [company_postal_code, setBusinessAddressPostalCode] = useState(
    companyDetails?.postal_code
  );
  const [company_postal_address, setBusinessPostalAddress] = useState(
    companyDetails?.postal_address
  );

  function previousPage() {
    setProfilePage(0);
  }

  useEffect(() => {
    saveCompanyLogo();
    getCompanyLogo();
  }, [companyLogo]);

  useEffect(() => {
    getBusiness();
  }, [user]);

  useEffect(() => {
    // SET COMPANY DETAILS
    setCompanyName(companyDetails?.name);
    setTradingName(companyDetails?.trading_name);
    setCompanyRegNo(companyDetails?.registration);
    setCompanyVatNo(companyDetails?.vat_no);
    setNatureOfBusiness(companyDetails?.nature_of_business);
    setIndustry(companyDetails?.industry);
    setCompanyFinancialYear(companyDetails?.financial_year_end);
    setCompanyContactPerson(companyDetails?.contact_person);
    setCompanyContactNo(companyDetails?.cell);
    setCompanyEmail(companyDetails?.email);
    setBusinessBankName(companyDetails?.bank_name);
    setBusinessBankBranchName(companyDetails?.bank_branch_name);
    setBusinessBankBranchCode(companyDetails?.bank_branch_code);
    setBusinessAddressBuilding(companyDetails?.building);
    setBusinessBankAccNo(companyDetails?.bank_account_no);
    setBusinessAddressStreeName(companyDetails?.street_name);
    setBusinessAddresssuburb(companyDetails?.suburb);
    setBusinessAddressCity(companyDetails?.city);
    setBusinessAddressPostalCode(companyDetails?.postal_code);
    setBusinessPostalAddress(companyDetails?.postal_address);
  }, [companyDetails]);

  const saveCompanyLogo = async () => {
    let formData = new FormData();
    if (companyLogo) {
      formData.append('type', 'company_logo');
      formData.append('document', companyLogo.file);

      const config = {
        headers: {
          'content-type': `multipart/form-data;`
        }
      };

      try {
        setShowLogoLoader(true);
        await axios.post(`users/${user.id}/documents`, formData, {
          ...config
        });
        setShowLogoLoader(false);
      } catch (e) {
        console.log(e);
        setShowLogoLoader(false);
        Sentry.captureException(e);
      }
    }
  };

  const updateProfile = async () => {
    console.log(cell);
    console.log(tax_no);
    try {
      const { data } = await axios.put(`/users/${user.id}`, {
        company_name: company_name,
        name: company_name,
        first_name: name,
        last_name: surname,
        cell: cell ? cell.replace('+', '') : cell,
        id_number: id_number,
        email: email,
        title: title,
        country: country,
        company_reg_no: companyRegNo,
        role: role,
        telephone: telephone,
        tax_no: tax_no,
        address_building_unit_no: address_building_unit_no,
        address_street_name: address_street_name,
        address_suburb: address_suburb,
        address_city: address_city,
        address_postal_code: address_postal_code,
        bank_name: bank_name,
        bank_branch_name: bank_branch_name,
        bank_branch_code: bank_branch_code,
        bank_account_no: bank_account_no,
        bank_account_holder: bank_account_holder,
        occupation: occupation,
        on_behalf_of: on_behalf_of,
        position_title: position_title,
        trading_name: trading_name,
        vat_no: vat_no,
        nature_of_business: nature_of_business,
        financial_year_end: financial_year_end,
        contact_person: contact_person,
        company_contact_no: company_contact_no,
        company_email: company_email,
        company_bank_name: company_bank_name,
        company_bank_branch_name: company_bank_branch_name,
        company_bank_branch_code: company_bank_branch_code,
        company_bank_account_no: company_bank_account_no,
        company_postal_code: company_postal_code,
        company_postal_address: company_postal_address,
        company_street_name: company_street_name,
        building: company_building,
        company_suburb: company_suburb,
        company_city: company_city,
        industry: industry
      });
      if (data.success) {
        setUser(data.user);
        window.location.href = '/my-profile';
      }
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  };

  const getCompanyLogo = async () => {
    try {
      const { data } = await axios.get(
        `users/${user.id}/documents?type=company_logo`
      );
      setLogo(data.company_logo);
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  };

  const getBusiness = async () => {
    try {
      const { data } = await axios.get(`/businesses/user/businesses`, {
        user: user
      });
      if (data.success) {
        setCompanyDetails(data.businesses[0]);
      }
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  };
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white'
        >
          <GoBackHeader previousPage={previousPage} pageName={'My Details'} />
          <div className='m-t-100 text-center p-relative'>
            <div className={styles.userInitials}>
              <div className={styles.centerInitials}>{user?.initials}</div>
            </div>
            <p className='fw-800 fs-18 mt-2'>
              {user?.first_name} {user?.last_name}
            </p>
          </div>

          <Form className={`bg-white ${styles.form}`}>
            {/* USER INFO */}
            <>
              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>TITLE</label>
                </div>
                <Form.Select onChange={(e) => setTitle(e.currentTarget.value)}>
                  <option disabled hidden>
                    Select
                  </option>
                  {TITLES.map((title, index) => {
                    return (
                      <option
                        key={index}
                        selected={title === user?.title}
                        value={title}
                      >
                        {title}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>FIRST NAME</label>
                </div>
                <Form.Control
                  type='text'
                  disabled={user && user.verified}
                  defaultValue={user?.first_name}
                  onChange={(e) => setFirstName(e.currentTarget.value)}
                  className={styles.input}
                />
                {errors?.first_name ? (
                  <small className={styles.error}>
                    Transaction name is required.
                  </small>
                ) : (
                  ''
                )}
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>LAST NAME</label>
                </div>
                <Form.Control
                  type='text'
                  disabled={user && user.verified}
                  defaultValue={user?.last_name}
                  onChange={(e) => setSurame(e.currentTarget.value)}
                  className={styles.input}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>ROLE</label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={user?.role}
                  onChange={(e) => setRole(e.currentTarget.value)}
                  className={styles.input}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>Nationality</label>
                </div>
                <Form.Select
                  onChange={(e) => setCountry(e.currentTarget.value)}
                >
                  <option disabled selected hidden>
                    Select
                  </option>
                  {countries.map((country) => {
                    return (
                      <option
                        value={country}
                        selected={country === user?.country}
                      >
                        {country}
                      </option>
                    );
                  })}
                </Form.Select>
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>ID/PASSPORT NUMBER</label>
                </div>
                <Form.Control
                  type='text'
                  disabled={user && user.verified}
                  defaultValue={user?.id_number}
                  onChange={(e) => setIdNumber(e.currentTarget.value)}
                  className={styles.input}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>MOBILE NUMBER</label>
                </div>
                <PhoneInput
                  className='phoneInput phone-number-input'
                  defaultCountry='ZA'
                  value={cell}
                  onChange={setCell}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>EMAIL</label>
                </div>
                <Form.Control
                  type='email'
                  defaultValue={user?.email}
                  onChange={(e) => setEmail(e.currentTarget.value)}
                  className={styles.input}
                />
              </Form.Group>
              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>TELL NO.</label>
                </div>
                <PhoneInput
                  className='phoneInput phone-number-input'
                  defaultCountry='ZA'
                  value={telephone}
                  onChange={setTelephone}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>TAX NUMBER</label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={tax_no}
                  onChange={(e) => setTaxNo(e.currentTarget.value)}
                  className={styles.input}
                />
              </Form.Group>
            </>

            {/* ADDRESS */}
            <>
              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>
                    ADDRESS - Building name / Unit number
                  </label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={address_building_unit_no}
                  onChange={(e) =>
                    setAddressBuildingUnitNo(e.currentTarget.value)
                  }
                  className={styles.input}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>ADDRESS - Street name</label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={address_street_name}
                  onChange={(e) => setAddressStreetName(e.currentTarget.value)}
                  className={styles.input}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>ADDRESS - suburb</label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={address_suburb}
                  onChange={(e) => setSetAddresssuburb(e.currentTarget.value)}
                  className={styles.input}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>ADDRESS - City</label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={address_city}
                  onChange={(e) => setAddressCity(e.currentTarget.value)}
                  className={styles.input}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>ADDRESS - Postal code</label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={address_postal_code}
                  onChange={(e) => setAddressPostalCode(e.currentTarget.value)}
                  className={styles.input}
                />
              </Form.Group>
            </>

            {/* BANK */}
            <>
              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>BANK - Bank name</label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={bank_name}
                  onChange={(e) => setBankName(e.currentTarget.value)}
                  className={styles.input}
                />
              </Form.Group>
              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>BANK - Branch name</label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={bank_branch_name}
                  onChange={(e) => setBankBranchName(e.currentTarget.value)}
                  className={styles.input}
                />
              </Form.Group>
              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>BANK - Branch code</label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={bank_branch_code}
                  onChange={(e) => setBankBranchCode(e.currentTarget.value)}
                  className={styles.input}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>BANK - Account number</label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={bank_account_no}
                  onChange={(e) => setBankAccountNo(e.currentTarget.value)}
                  className={styles.input}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>
                    BANK - Account holder name
                  </label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={bank_account_holder}
                  onChange={(e) =>
                    setBankAccountHolderName(e.currentTarget.value)
                  }
                  className={styles.input}
                />
              </Form.Group>
            </>

            {/* WORK INFORMATION */}
            <>
              <h5 className='form-section-heading'>Work Information</h5>
              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>OCCUPATION</label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={occupation}
                  onChange={(e) => setOccupation(e.currentTarget.value)}
                  className={styles.input}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>
                    ON BEHALF OF / REPRESENTING
                  </label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={on_behalf_of}
                  onChange={(e) => setOnBehalfOf(e.currentTarget.value)}
                  className={styles.input}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>POSITION / TITLE</label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={position_title}
                  onChange={(e) => setPositionTitle(e.currentTarget.value)}
                  className={styles.input}
                />
              </Form.Group>
            </>

            {/* COMPANY INFORMATION */}
            <>
              <h5 className='form-section-heading'>Company Information</h5>
              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>COMPANY NAME</label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={company_name}
                  onChange={(e) => setCompanyName(e.currentTarget.value)}
                  className={styles.input}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>TRADING NAME</label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={trading_name}
                  onChange={(e) => setTradingName(e.currentTarget.value)}
                  className={styles.input}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>
                    COMPANY REGISTRATION NUMBER
                  </label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={companyRegNo}
                  onChange={(e) => setCompanyRegNo(e.currentTarget.value)}
                  className={styles.input}
                />
              </Form.Group>
              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>COMPANY VAT NUMBER</label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={vat_no}
                  onChange={(e) => setCompanyVatNo(e.currentTarget.value)}
                  className={styles.input}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>NATURE OF BUSINESS</label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={nature_of_business}
                  onChange={(e) => setNatureOfBusiness(e.currentTarget.value)}
                  className={styles.input}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>INDUSTRY</label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={industry}
                  onChange={(e) => setIndustry(e.currentTarget.value)}
                  className={styles.input}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>
                    COMPANY FINANCIAL YEAR END
                  </label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={financial_year_end}
                  onChange={(e) =>
                    setCompanyFinancialYear(e.currentTarget.value)
                  }
                  className={styles.input}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>COMPANY CONTACT PERSON</label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={contact_person}
                  onChange={(e) =>
                    setCompanyContactPerson(e.currentTarget.value)
                  }
                  className={styles.input}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>COMPANY CONTACT NUMBER</label>
                </div>
                <PhoneInput
                  className='phoneInput phone-number-input'
                  defaultCountry='ZA'
                  value={company_contact_no}
                  onChange={setCompanyContactNo}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>COMPANY EMAIL ADDRESS</label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={company_email}
                  onChange={(e) => setCompanyEmail(e.currentTarget.value)}
                  className={styles.input}
                />
              </Form.Group>
            </>

            {/* COMPANY BANK INFO */}
            <>
              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>
                    BUSINESS BANK - Bank name
                  </label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={company_bank_name}
                  onChange={(e) => setBusinessBankName(e.currentTarget.value)}
                  className={styles.input}
                />
              </Form.Group>
              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>
                    BUSINESS BANK - Branch name
                  </label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={company_bank_branch_name}
                  onChange={(e) =>
                    setBusinessBankBranchName(e.currentTarget.value)
                  }
                  className={styles.input}
                />
              </Form.Group>
              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>
                    BUSINESS BANK - Branch code
                  </label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={company_bank_branch_code}
                  onChange={(e) =>
                    setBusinessBankBranchCode(e.currentTarget.value)
                  }
                  className={styles.input}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>
                    BUSINESS BANK - Account number
                  </label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={company_bank_account_no}
                  onChange={(e) => setBusinessBankAccNo(e.currentTarget.value)}
                  className={styles.input}
                />
              </Form.Group>
            </>

            {/* COMPANY ADDRESS INFO */}
            <>
              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>
                    BUSINESS ADDRESS - Building name
                  </label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={company_building}
                  onChange={(e) =>
                    setBusinessAddressBuilding(e.currentTarget.value)
                  }
                  className={styles.input}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>
                    BUSINESS ADDRESS - Street name
                  </label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={company_street_name}
                  onChange={(e) =>
                    setBusinessAddressStreeName(e.currentTarget.value)
                  }
                  className={styles.input}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>
                    BUSINESS ADDRESS - suburb
                  </label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={company_suburb}
                  onChange={(e) =>
                    setBusinessAddresssuburb(e.currentTarget.value)
                  }
                  className={styles.input}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>
                    BUSINESS ADDRESS - City
                  </label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={company_city}
                  onChange={(e) =>
                    setBusinessAddressCity(e.currentTarget.value)
                  }
                  className={styles.input}
                />
              </Form.Group>

              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>
                    BUSINESS ADDRESS - Postal code
                  </label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={company_postal_code}
                  onChange={(e) =>
                    setBusinessAddressPostalCode(e.currentTarget.value)
                  }
                  className={styles.input}
                />
              </Form.Group>
              <Form.Group className='mb-4'>
                <div className='pb-2 ps-4'>
                  <label className={styles.label}>Postal Address</label>
                </div>
                <Form.Control
                  type='text'
                  defaultValue={company_postal_address}
                  onChange={(e) =>
                    setBusinessPostalAddress(e.currentTarget.value)
                  }
                  className={styles.input}
                />
              </Form.Group>
            </>

            <Row className='m0'>
              <Col
                lg={{ span: 4, offset: 4 }}
                md={{ span: 6, offset: 3 }}
                className={styles.footerInner}
              >
                <Button
                  variant='dark'
                  className='btn-block btn-rounded mt-1 fw-700 fs-16'
                  onClick={(e) => updateProfile()}
                >
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </motion.div>
  );
}
