export default function DocumentItem({ document, onClick, count }) {
  function handleClick(document) {
    onClick(document);
  }

  return (
    <div
      onClick={() => handleClick(document)}
      className='mb-2 cardWhite h-70 pointer card fade-in'
    >
      <div className='styles_cardBody__s-IRm card-body document-details-card'>
        <span className='badge badge-info fw-600'>PDF</span>
        <div className='styles_cardBodyText__DhJ4d card-text'>
          <div>
            <p className='m0 mb-0 fs-14 fw-700'>{document.title}</p>
            <span className='color-lightgray fs-12 fw-700'>
              {count > 0 ? `${count} additional field` : ``}
              {count > 1 ? 's' : ''}
            </span>
          </div>
        </div>
        <div className='styles_viewFileBtn__XjyRN'>
          <img
            src='/static/media/chevron-right.00ff529e1b01f37594da63a561b89dc1.svg'
            alt='Chevron Right'
            className='icon'
          />
        </div>
      </div>
    </div>
  );
}
