import SigningHeader from 'components/Pages/Signing/Header/Header';
import appContext from 'context/app-context';
import { motion } from 'framer-motion';
import { useContext } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Webcam from 'react-webcam';
import styles from './styles.module.css';

import * as Sentry from '@sentry/react';
import CameraIcon from 'assets/images/camera-white.svg';
import Identification from 'assets/images/identification.svg';
import RefreshIcon from 'assets/images/refresh.svg';
import WhiteChevronRightIcon from 'assets/images/white-chevron-right.svg';
import axios from 'axios';
import SpinnerAnimation from 'components/Spinners/SpinnerAnimation/SpinnerAnimation';
import { useCallback, useRef, useState } from 'react';

import { isMobile } from 'react-device-detect';

const videoConstraints = {
  facingMode: getCameraFacingMode(),
  forceScreenshotSourceSize: 'true',
  screenshotQuality: 1
};

function getCameraFacingMode() {
  if (isMobile) {
    return {
      exact: 'environment'
    };
  }

  return 'user';
}

export default function IdentityPassportPhoto() {
  const {
    setCurrentPage,
    idNumber,
    manualVerification,
    documents,
    country,
    email
  } = useContext(appContext);
  const [photo, setPhoto] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [verifyMe, setVerifyMe] = useState(false);
  const [continuePopup, setContinuePopup] = useState(true);

  function previousPage() {
    setCurrentPage(2);
  }

  function goBackToAddUserInfo() {
    setCurrentPage(8);
  }

  function closePopup() {
    setContinuePopup(false);
  }

  const webcamRef = useRef(null);
  const capturePhoto = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPhoto(imageSrc);
  }, [webcamRef]);

  const proceedBtn = async () => {
    setShowLoader(true);
    try {
      const { data } = await axios.post(`id-document`, {
        id_number: idNumber,
        id_document: photo,
        country: country,
        email: email
      });

      if (data.success === true) {
        if (manualVerification) {
          if (documents.length > 1) {
            setCurrentPage(6);
            return;
          }
          setCurrentPage(7);
          return;
        }
        setVerifyMe(true);
        //Selfie verification
        setCurrentPage(4);
      }
      setShowLoader(false);
    } catch (e) {
      //Todo remove
      setShowLoader(false);
      console.log(e);
      Sentry.captureException(e);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      {continuePopup ? <div className={styles.overlay}></div> : null}
      <Row className='m0'>
        {/* <SigningHeader previousPage={previousPage} /> */}
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page'
        >
          <div className={styles.main}>
            <div className='text-center p-t-100'>
              {photo ? (
                <>
                  <p className='fw-700 fs-16 m-b-20'>
                    If the image is sharp and clear
                    <br />
                    please proceed
                  </p>
                  <div className='p-relative'>
                    <img
                      src={photo}
                      alt='id passport'
                      className={styles.photo}
                    />
                  </div>
                  <p className='fw-700 fs-16 p-t-30'>
                    If unclear, please retake the photo
                  </p>
                  <Row>
                    <Col
                      lg={{ span: 4, offset: 4 }}
                      md={{ span: 8, offset: 2 }}
                      xs={{ span: 12, offset: 0 }}
                      className={`${styles.continueOrRetake}`}
                    >
                      <Col>
                        <Button
                          variant='dark'
                          className='btn-block btn-rounded fw-700'
                          onClick={proceedBtn}
                        >
                          Continue
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          variant='outline-dark'
                          className='btn-block btn-rounded fw-700 m-t-15 btn-no-hover-change'
                          onClick={() => setPhoto(null)}
                        >
                          Retake Photo
                        </Button>
                      </Col>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <div className=''>
                    <p className='fw-700 fs-16 m-b-30'>
                      Take a photo of the front of your
                      <br />
                      identity card or passport document
                    </p>
                    <div className={styles.webcamContainer}>
                      <div className={styles.webcamBorders}>
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                        <div />
                      </div>
                      <Webcam
                        ref={webcamRef}
                        audio={false}
                        height={480}
                        width={640}
                        screenshotFormat='image/jpeg'
                        className={styles.webcam}
                        forceScreenshotSourceSize={true}
                        //height='100%'
                        videoConstraints={videoConstraints}
                      />
                    </div>
                    <p className='fw-700 fs-16 m-t-30'>
                      Please ensure that the photo is clearly
                      <br />
                      visible and in-focus.
                    </p>
                    <div className={styles.cameraBtns}>
                      <Button
                        variant='dark'
                        className='rounded-circle btn-md-rounded p0 pulse-animate rotate'
                        onClick={capturePhoto}
                      >
                        <img src={CameraIcon} alt='selfie' />
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>

            {continuePopup && (
              <div className={styles.footerAnimate}>
                <Row className='m0'>
                  <Col
                    lg={{ span: 4, offset: 4 }}
                    md={{ span: 6, offset: 3 }}
                    className='bg-white border-rounded-top'
                  >
                    <div className='pb-4 pt-5'>
                      <img
                        src={Identification}
                        alt='ID'
                        className={styles.icon}
                      />
                    </div>
                    <div className='ps-3 pe-3 pt-3 pb-5'>
                      <p className='fw-700 fs-16'>
                        Take a photo of your
                        <br />
                        identity or passport document.
                        <br />
                        <br />
                        Please ensure that the photo is clearly
                        <br />
                        visible and in-focus
                      </p>
                    </div>
                    {showLoader ? (
                      <SpinnerAnimation />
                    ) : (
                      <Button
                        variant='dark'
                        className='btn-block btn-rounded fw-700 fs-16'
                        onClick={closePopup}
                      >
                        Continue
                      </Button>
                    )}
                    <p className='mt-4 mb-3' onClick={goBackToAddUserInfo}>
                      <span className='pointer fw-700 fs-16'>Cancel</span>
                    </p>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </motion.div>
  );
}
