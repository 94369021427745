import { Viewer, Worker } from '@react-pdf-viewer/core';
import { zoomPlugin } from '@react-pdf-viewer/zoom';
import downloadIcon from 'assets/images/download-white.svg';
import GoBackHeader from 'components/GoBackHeader/GoBackHeader';
import appContext from 'context/app-context';
import { motion } from 'framer-motion';
import { useContext, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import packageJson from '../../../../../package.json';
import styles from './styles.module.css';

// PDF styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/zoom/lib/styles/index.css';

export default function ViewInvoice() {
  const { setBillingPage, invoice } = useContext(appContext);

  useEffect(() => {
    console.log('invoice here', invoice);
  }, [invoice]);

  const invoiceUrl = `${process.env.REACT_APP_BASE_URL}invoices/${invoice?.uuid}/view`;
  const downloadUrl = `${process.env.REACT_APP_BASE_PATH}invoices/${invoice?.uuid}/download`;

  const pdfjsVersion = packageJson.dependencies['pdfjs-dist'];
  const zoomPluginInstance = zoomPlugin();
  const { Zoom } = zoomPluginInstance;

  function previousPage() {
    setBillingPage(0);
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Col
        xs={{ span: 12 }}
        lg={{ span: 8, offset: 2 }}
        className='bg-white min-height-full-page'
      >
        <GoBackHeader previousPage={previousPage} pageName={invoice?.name} />
        <div className={`pdf-viewer ${styles.main}`}>
          <div
            className='fileViewerZoomButton'
            style={{
              position: 'fixed',
              right: '20px',
              backgroundColor: '#fff',
              borderRadius: '6px',
              zIndex: '1'
            }}
          >
            <Zoom levels={[0.4, 0.8, 1, 1.2, 1.5, 2.4, 3.2]} />
          </div>
          <Worker
            workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}
          >
            <div className={styles.docViewer}>
              <Viewer
                className='border-radius-6'
                fileUrl={invoiceUrl}
                plugins={[zoomPluginInstance]}
              />
            </div>
          </Worker>
          <div className={`pulse-animate ${styles.btnSign}`}>
            <a href={downloadUrl} target='_blank' rel='noreferrer'>
              <img
                src={downloadIcon}
                alt='sign'
                className={` btn-rounded ${styles.signBtn}`}
              />
            </a>
          </div>
        </div>
      </Col>
    </motion.div>
  );
}
