import { useEffect } from 'react';

import { Route, Routes, useLocation } from 'react-router-dom';
import { Dashboard } from 'pages/Dashboard/Dashboard';
import { Sign } from 'pages/Signing/Sign';
import { Continue } from 'pages/Continue/Continue';
import { Send } from 'pages/Sending/Send';
import { Billing } from 'pages/Billing/Billing';
import { AnimatePresence } from 'framer-motion';
import { Profile } from 'pages/Profile/Profile';
import { BusinessProfile } from 'pages/BusinessProfile/BusinessProfile';
import BusinessDashboard from 'pages/BusinessDashboard/BusinessDashboard';
import { TeamMembersPage } from 'pages/TeamMembers/TeamMembers';
import WorkSpaces from 'pages/WorkSpaces/WorkSpaces';
import Transactions from 'pages/Transactions/Transactions';
import { ShortUrl } from 'pages/ShortUrl/ShortUrl';
import AddBusiness from 'components/Pages/Profile/AddBusiness/AddBusiness';

export const App = () => {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <div>
        <AnimatePresence exitBeforeEnter>
          <Routes location={location} key={location.pathname}>
            <Route path='/' element={<Dashboard />} />
            <Route path='/:id' element={<ShortUrl />} />
            <Route path='/sign' element={<Sign />} />
            <Route path='/continue' element={<Continue />} />
            <Route path='/send' element={<Send />} />
            <Route path='/billing' element={<Billing />} />
            <Route path='/my-profile' element={<Profile />} />
            <Route path='/add-business-profile' element={<AddBusiness />} />
            {/* <Route path='/business-profile' element={<BusinessProfile />} />
            <Route path='/business-dashboard' element={<BusinessDashboard />} />
            <Route path='/team-members' element={<TeamMembersPage />} />
            <Route path='/workspaces' element={<WorkSpaces />} /> */}
            <Route path='/transactions' element={<Transactions />} />
          </Routes>
        </AnimatePresence>
      </div>
    </div>
  );
};
