import { useContext, useEffect, useState } from 'react';
import styles from './styles.module.css';
import { useNavigate } from 'react-router-dom';
import appContext from 'context/app-context';
import { motion } from 'framer-motion';
import { BillingCard } from '../BillingCard';
import { Button, Card, Col, Row } from 'react-bootstrap';
import Figure from 'react-bootstrap/Figure';
import EmptyCard from 'assets/images/empty-card.svg';
import GoBackHeader from 'components/GoBackHeader/GoBackHeader';
import axios from 'axios';
import SpinnerAnimation from 'components/Spinners/SpinnerAnimation/SpinnerAnimation';
import Visa from 'assets/images/visa.svg';
import MasterCard from 'assets/images/mastercard.svg';
import Amex from 'assets/images/amex.svg';
import Invoices from '../Invoices/Invoices';
import * as Sentry from '@sentry/react';

export default function BillingPage() {
  const { setBillingPage, card, user, getCard } = useContext(appContext);
  const [billing, setBilling] = useState(null);
  const [month, setMonth] = useState('');
  const [verifications, setVerifications] = useState(null);
  const [credits, setCredits] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [invoices, setInvoices] = useState([]);
  const [deleteCard, setDeleteCard] = useState(false);

  let navigate = useNavigate();
  const previousPage = () => {
    // If normal user, navigate to '/'
    let path = `/`;
    // else if business account, navigate to '/business-dashboard'

    // let path = `/business-dashboard`;
    navigate(path);
  };

  useEffect(() => {
    getCard();
    getBillings();
    getInvoices();
    console.log(billing);
  }, [user]);

  const removeCard = async () => {
    try {
      setShowLoader(true);
      await axios.delete(`users/${user.id}/cards/${card.id}/delete`);
      await getCard();
      setShowLoader(false);
      setDeleteCard(false);
      getBillings();
      getInvoices();
    } catch (e) {
      console.log(e);
      setShowLoader(false);
      setDeleteCard(false);
      Sentry.captureException(e);
    }
  };

  const getBillings = async () => {
    try {
      setShowLoader(true);
      const { data } = await axios.get(`/billings`);
      console.log(data);
      if (data.success) {
        setShowLoader(false);
        setBilling(data.billings);
        setMonth(data.date);
        setVerifications(data.verifications);
        setCredits(data.credits);
      }
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  };

  async function getInvoices() {
    try {
      const { data } = await axios.get(`/invoices`);
      console.log(data.invoices);
      if (data.success) {
        setInvoices(data.invoices);
      }
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      {deleteCard && <div className={styles.overlay}></div>}
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page'
        >
          <div className='p-b-150'>
            <GoBackHeader previousPage={previousPage} pageName={'Billing'} />
            <div className='p-t-100' />
            <BillingCard
              billing={billing}
              date={month}
              verifications={verifications}
              credits={credits}
              showLoader={showLoader}
            />
            <div className='pe-3 ps-3'>
              <Card className='mb-2 cardWhite mt-4'>
                <Card.Body className={styles.cardBody}>
                  <Card.Text className={styles.cardBodyText} as='h6'>
                    <p className='mb-4 fs-18 fw-800'>Payment Method</p>
                    {showLoader ? (
                      <>
                        <SpinnerAnimation />
                      </>
                    ) : (
                      <>
                        {card ? (
                          <Figure className='m0 w-100 d-flex align-items-center'>
                            {card.card_brand === 'MasterCard' ? (
                              <Figure.Image
                                width={64}
                                src={MasterCard}
                                alt={card.card_brand}
                                className='mb-0 pull-left'
                              />
                            ) : card.card_brand === 'Visa' ? (
                              <Figure.Image
                                width={64}
                                src={Visa}
                                alt={card.card_brand}
                                className='mb-0 pull-left'
                              />
                            ) : (
                              <Figure.Image
                                width={64}
                                src={Amex}
                                alt={card.card_brand}
                                className='mb-0 pull-left'
                              />
                            )}
                            <Figure.Caption className='ms-3'>
                              <p className='m0 fw-700'>{card?.name}</p>
                              <p className='m0'>Card ending xx {card?.last}</p>
                            </Figure.Caption>
                          </Figure>
                        ) : (
                          <Figure className='m0 w-100 d-flex align-items-center'>
                            <Figure.Image
                              width={64}
                              alt='visa'
                              src={EmptyCard}
                              className='mb-0 pull-left'
                            />
                            <Figure.Caption className='ms-3'>
                              <p className='m0 color-lightgray'>
                                No card added
                              </p>
                            </Figure.Caption>
                          </Figure>
                        )}
                      </>
                    )}
                  </Card.Text>
                </Card.Body>
              </Card>
            </div>
            <div className='pe-3 ps-3 pt-3'>
              <Invoices invoices={invoices} />
            </div>
            <div className={styles.footer}>
              <Row className='m0'>
                <Col
                  lg={{ span: 4, offset: 4 }}
                  md={{ span: 6, offset: 3 }}
                  className={styles.addCard}
                >
                  {!card ? (
                    <Button
                      variant='dark'
                      className='btn-block btn-rounded mt-1 fw-700 fs-16'
                      onClick={(e) => setBillingPage(1)}
                    >
                      Add card
                    </Button>
                  ) : (
                    <Button
                      variant='outline-dark'
                      className='btn-block btn-rounded mt-1 fw-700 fs-16'
                      onClick={(e) => setDeleteCard(true)}
                    >
                      Remove card
                    </Button>
                  )}
                </Col>
              </Row>
            </div>

            {deleteCard && (
              <div className={styles.footerAnimate}>
                <Row className='m0'>
                  <Col
                    lg={{ span: 4, offset: 4 }}
                    md={{ span: 6, offset: 3 }}
                    className={styles.deleteCard}
                  >
                    <div className='ps-3 pe-3 pt-3'>
                      <p className='fw-700 fs-16'>
                        Are you sure you want to
                        <br />
                        remove your card?
                      </p>
                      <p className='fw-500 mb-4'>
                        A payment method is required to send
                        <br />
                        files for signing. Any outstanding
                        <br />
                        payments will be billed upon removal of
                        <br />
                        your payment method.
                      </p>
                    </div>
                    {showLoader ? (
                      <SpinnerAnimation />
                    ) : (
                      <Button
                        variant='dark'
                        className='btn-block btn-rounded fw-700 fs-16'
                        onClick={(e) => removeCard()}
                      >
                        Remove Card
                      </Button>
                    )}
                    <p className='mt-3' onClick={() => setDeleteCard(false)}>
                      <span className='pointer fw-700'>Cancel</span>
                    </p>
                  </Col>
                </Row>
              </div>
            )}
          </div>
        </Col>
      </Row>
    </motion.div>
  );
}
