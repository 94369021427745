import * as Sentry from '@sentry/react';
import AddUserIcon from 'assets/images/add-user.svg';
import ChevronLeftIcon from 'assets/images/chevron-left.svg';
import Cross from 'assets/images/cross.svg';
import home from 'assets/images/home.svg';
import axios from 'axios';
import SpinnerAnimation from 'components/Spinners/SpinnerAnimation/SpinnerAnimation';
import SpinnerSmall from 'components/Spinners/SpinnerSmall/SpinnerSmall';
import appContext from 'context/app-context';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';

export default function Signatoties(props) {
  const [users, setUsers] = useState([]);
  const [signers, setSigners] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showLoaderSpinner, setShowLoaderSpinner] = useState(false);
  const [showLoaderDeleteUser, setShowLoaderDeleteUser] = useState(false);
  const [senderAdded, setSenderAdded] = useState(false);
  const [userKey, setUserKey] = useState('');
  const navigate = useNavigate();

  const { user, setSendingPage, currentTransaction } = useContext(appContext);

  useEffect(() => {
    window.scrollTo(0, 0);
    getSignatories();
    getFrequentUsers();
    // getWorkspaceUsers();
    let senderAdd = sessionStorage.getItem('sender_added');
    if (senderAdd) {
      setSenderAdded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentTransaction.closed == 1) {
      setSendingPage(3);
    }
  }, [currentTransaction]);

  async function getSignatories() {
    window.scrollTo(0, 0);
    try {
      setShowLoaderSpinner(true);
      const { data } = await axios.get(
        `transactions/${currentTransaction.id}/signatories`
      );
      await getFrequentUsers();
      setSigners(data.signatories);
      data.signatories.map((signatory) => {
        if (signatory.uuid === user.id) {
          setSenderAdded(true);
        }
        return true;
      });
      setShowLoaderSpinner(false);
    } catch (e) {
      setShowLoaderSpinner(false);
      console.log(e);
      Sentry.captureException(e);
    }
  }

  async function addSignee(signer, isSender) {
    window.scrollTo(0, 0);
    setUserKey(signer.id);
    try {
      setShowLoader(true);
      const { data } = await axios.post(
        `transactions/${currentTransaction.id}/signatories/new`,
        {
          first_name: signer.first_name,
          last_name: signer.last_name,
          //TODO: make sure cell number is correct
          cell: signer.cell,
          id_number: signer.id_number,
          email: signer.email
        }
      );
      if (data.success) {
        await getFrequentUsers();
        setShowLoader(false);
        if (isSender) {
          sessionStorage.setItem('sender_added', true);
          setSenderAdded(true);
        }
        setSigners(data.signatories);
        setUserKey('');
        // await getWorkspaceUsers();
      }
    } catch (e) {
      setShowLoader(false);
      setUserKey('');
      console.log(e);
      Sentry.captureException(e);
    }
  }

  function goToNext() {
    sessionStorage.removeItem('sender_added');
    setSendingPage(8);
  }

  async function deleteSignee(signer) {
    setUserKey(signer.id);
    try {
      setShowLoaderDeleteUser(true);
      const { data } = await axios.delete(
        `transactions/${currentTransaction.id}/signatories/${signer.id}/delete`
      );
      if (signer.uuid === user.id) {
        sessionStorage.removeItem('sender_added');
        setSenderAdded(false);
      }
      await getFrequentUsers();
      setSigners(data.signatories);
      setShowLoaderDeleteUser(false);
      setUserKey('');
      // await getWorkspaceUsers();
    } catch (e) {
      setShowLoaderDeleteUser(false);
      setUserKey('');
      console.log(e);
      Sentry.captureException(e);
    }
  }

  // eslint-disable-next-line no-unused-vars
  async function getWorkspaceUsers() {
    try {
      const { data } = await axios.get(
        `workspaces/${currentTransaction.workspace.uuid}/members?transactionUuid=${currentTransaction.id}`
      );
      setUsers(data.users);
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  }

  async function getFrequentUsers() {
    try {
      const { data } = await axios.get(
        `signatories/frequent?transaction_id=${currentTransaction.id}`
      );
      setUsers(data.users);
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Row className='m0 '>
        <Col xs={{ span: 12 }} className='p0'>
          <div className={styles.header}>
            <div
              className={styles.chevronLeftIcon}
              onClick={() => setSendingPage(1)}
            >
              <img src={ChevronLeftIcon} alt='go back' />
            </div>
            <p className='mb-1'>Step 2 of 4</p>
            <p className='m0 fw-700 fs-16'>Who will be signing?</p>
            <img
              src={home}
              alt='home'
              className={styles.homeBtn}
              onClick={() => navigate('/')}
            />
          </div>
        </Col>
      </Row>
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page p-t-100'
        >
          {!signers.length ? (
            <>
              {showLoaderSpinner ? (
                <div className='m-r-15 d-flex align-items-center'>
                  <SpinnerAnimation />
                </div>
              ) : (
                <div className={styles.emptyContainer}>
                  <p className='m0 fs-12 fw-400'>No one added for signing</p>
                </div>
              )}
            </>
          ) : (
            <div className='pt-2 pb-3'>
              {signers.map((signer, id) => (
                <Card key={id} className='mb-2 cardWhite'>
                  <Card.Body className={styles.cardBody}>
                    <Card.Text className={styles.cardBodyText}>
                      {signer.first_name ? (
                        <p className='m0 fw-700 fs-14'>
                          {signer.first_name} {signer.last_name}
                        </p>
                      ) : (
                        <p className='m0 fw-700 fs-14 lowercase'>
                          {signer.email}
                        </p>
                      )}
                    </Card.Text>

                    {showLoaderDeleteUser && signer.id == userKey ? (
                      <div className='m-r-15 d-flex align-items-center'>
                        <SpinnerSmall />
                      </div>
                    ) : signer.signed_at ? (
                      'signed'
                    ) : (
                      <div className={styles.viewFileBtn}>
                        <img
                          src={Cross}
                          alt='delete'
                          className='h-8'
                          onClick={(e) => deleteSignee(signer)}
                        />
                      </div>
                    )}
                  </Card.Body>
                </Card>
              ))}
              {/* <SelectedSignees deleteSignee={deleteSignee} signers={signers} /> */}
            </div>
          )}
          <div className='text-center'>
            <img src={AddUserIcon} className={styles.addUserIcon} />
            <Button
              variant='dark'
              className='btn-block btn-shadow btn-rounded p-t-10 p-b-10'
              // setSendingPage = AddSignee
              onClick={(e) => setSendingPage(6)}
            >
              <span className='m0 fw-700 fs-16'>Add Someone</span>
            </Button>
          </div>
          <div className='mt-3 p-b-200'>
            <div className='text-center pt-4'>
              <p className='m0 fw-700 fs-12 color-gray'>SAVED SIGNERS</p>
            </div>
            {!senderAdded && !user?.has_business ? (
              <div className={styles.signeeContainer}>
                <div className={styles.signee}>
                  {user?.first_name ? (
                    // <p className='m0 w-full'>
                    //   <span className='fw-700 caps'>{user.last_name}, </span>
                    //   <span className='caps'>{user.first_name} (me)</span>{' '}
                    // </p>
                    <p className='m0 w-full'>
                      <span className='fw-700'>Me</span>
                    </p>
                  ) : (
                    <p className='m0 fw-700 w-full fs-14'>{user?.email} (me)</p>
                  )}

                  {showLoader && user.id == userKey ? (
                    <div className='m-r-20 d-flex align-items-center h-26'>
                      <SpinnerSmall />
                    </div>
                  ) : (
                    <Button
                      variant='light'
                      className='btn-white btn-shadow btn-rounded btn-sm'
                      onClick={(e) => addSignee(user, true)}
                    >
                      <p className='m0 fs-12 fw-700'>Add</p>
                    </Button>
                  )}
                </div>
              </div>
            ) : (
              ''
            )}
            {users.map((user, id) => (
              <div key={id} className={styles.signeeContainer}>
                <div className={styles.signee}>
                  {user.first_name ? (
                    <p className='m0 w-full'>
                      <span className='fw-700'>{user.last_name}, </span>
                      <span className='fw-700'>{user.first_name} </span>{' '}
                      <span className={styles.center}>{user.email} </span>
                    </p>
                  ) : (
                    <p className='m0 w-full'>
                      <span className='fw-700'>{user.email}</span>
                    </p>
                  )}

                  {showLoader && user.id == userKey ? (
                    <div className='m-r-20 d-flex align-items-center h-26'>
                      <SpinnerSmall />
                    </div>
                  ) : (
                    <Button
                      variant='light'
                      className='btn-white btn-shadow btn-rounded btn-sm'
                      onClick={(e) => addSignee(user, false)}
                    >
                      <p className='m0 fs-12 fw-700'>Add</p>
                    </Button>
                  )}
                </div>
              </div>
            ))}
          </div>

          {signers.length ? (
            <div className={styles.footerAnimate}>
              <Button
                variant='dark'
                className='btn-block btn-rounded mb-4 fw-700'
                onClick={(e) => goToNext(e)}
              >
                Continue
              </Button>

              <span
                className='m0 fw-700 fs-16 pointer'
                onClick={(e) => setSendingPage(1)}
              >
                Back
              </span>
            </div>
          ) : (
            ''
          )}

          <div className={styles.footer}>
            <span
              className='fw-700 fs-16 pointer'
              onClick={(e) => setSendingPage(1)}
            >
              Back
            </span>
          </div>
        </Col>
      </Row>
    </motion.div>
  );
}
