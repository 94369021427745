import * as Sentry from '@sentry/react';
import Check from 'assets/images/check.svg';
import CloudUpload from 'assets/images/cloud-upload.svg';
import Cross from 'assets/images/cross.svg';
import axios from 'axios';
import { ChevronRightIcon } from 'components/Icons';
import SpinnerAnimation from 'components/Spinners/SpinnerAnimation/SpinnerAnimation';
import appContext from 'context/app-context';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useFileUpload } from 'use-file-upload';
import ProgressLine from '../ProgressLine/ProgressLine';
import styles from './styles.module.css';

export default function UploadFiles(props) {
  const [files, selectFiles] = useFileUpload();
  const [transactionFiles, setTransactionFiles] = useState([]);
  const { setSendingPage, setCurrentTransaction, setCurrentDocument } =
    useContext(appContext);
  const [showLoader, setShowLoader] = useState(false);
  const [showText, setShowText] = useState(false);
  const [showProgressBar, setShowProgressBar] = useState(true);
  const [hasFiles, setHasFiles] = useState(false);
  const [transactionId, setTransactionId] = useState('');
  const [transaction, setTransaction] = useState(null);
  const [documents, setDocuments] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    let newFiles = transactionFiles;
    if (files) {
      setHasFiles(true);
      files.forEach((file) => {
        newFiles.push(file);
      });

      let uniqueArray = uniqFiles(newFiles);

      uniqueArray = sortObjectsByName(uniqueArray);

      setTransactionFiles([...uniqueArray]);
      window.scrollTo(0, 125);

      setTimeout(() => {
        setShowText(true);
        setShowProgressBar(false);
      }, 2000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  useEffect(() => {
    let transactionId = sessionStorage.getItem('transactionId');
    if (transactionId) {
      setTransactionId(transactionId);
      getTransaction(transactionId);
    }
  }, []);

  const uniqFiles = (files) => {
    const names = files.map((o) => o.name);
    return files.filter(({ name }, index) => !names.includes(name, index + 1));
  };

  const deleteDocument = (document) => {
    window.scrollTo(0, 125);
    const docs = transactionFiles.filter((file) => file.name !== document.name);
    setTransactionFiles(docs);
    if (docs == 0) {
      setHasFiles(false);
      setShowText(true);
    }
  };

  function sortObjectsByName(objects) {
    return objects.sort((a, b) => {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();

      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
  }

  async function deleteTransactionDocument(document) {
    try {
      await axios.delete(
        `transactions/${transactionId}/documents/${document.uuid}/delete`
      );
      return getTransaction(transactionId);
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  }

  async function getTransaction(transactionId) {
    try {
      const { data } = await axios.get(`transactions/${transactionId}`);
      setCurrentTransaction(data.data);
      setTransaction(data.data);
      setDocuments(data.data.documents);
      if (data.data?.documents.length) {
        setHasFiles(true);
      }
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  }

  async function updateTransaction() {
    // if (!transactionFiles.length) {
    //   return setSendingPage(2);
    // }
    let formData = new FormData();
    formData.append('name', props.transactionName);
    formData.append('workspaceUuid', '');
    for (let i = 0; i < transactionFiles.length; i++) {
      formData.append(`documents[${i}]`, transactionFiles[i].file);
    }

    const config = {
      headers: {
        'content-type': `multipart/form-data;`
      }
    };

    setShowLoader(true);
    props.setShowError(false);
    try {
      const { data } = await axios.post(
        `transactions/${transactionId}/update`,
        formData,
        {
          ...config
        }
      );

      if (data.success) {
        sessionStorage.setItem('transactionId', data.transaction.id);
        setSendingPage(2);
        setCurrentTransaction(data.transaction);
      }

      setShowLoader(false);

      return getTransaction(transactionId);
    } catch (e) {
      setShowLoader(false);
      console.log(e);
      Sentry.captureException(e);
    }
  }

  const saveTransaction = async () => {
    if (transactionId) {
      return updateTransaction();
    }
    sessionStorage.setItem('transactionName', props.transactionName);
    let formData = new FormData();
    formData.append('name', props.transactionName);
    //TODO:: add workspaceUuid
    formData.append('workspaceUuid', '');
    for (let i = 0; i < transactionFiles.length; i++) {
      formData.append(`documents[${i}]`, transactionFiles[i].file);
    }

    const config = {
      headers: {
        'content-type': `multipart/form-data;`
      }
    };

    if (props.transactionName) {
      setShowLoader(true);
      props.setShowError(false);
      try {
        const { data } = await axios.post('transactions/create', formData, {
          ...config
        });

        if (data.success) {
          sessionStorage.setItem('transactionId', data.transaction.id);
          setSendingPage(2);
          setCurrentTransaction(data.transaction);
        }
        setShowLoader(false);
      } catch (e) {
        setShowLoader(false);
        console.log(e);
        Sentry.captureException(e);
      }
    } else {
      props.setShowError(true);
    }
  };

  async function deleteTransaction() {
    if (transaction?.signed || transaction?.sent_complete) {
      sessionStorage.removeItem('transactionId');
      sessionStorage.removeItem('transactionName');
      window.location.href = `/transactions?transaction=${transactionId}`;
    } else {
      await props.deleteTransaction(false);
      window.location.reload(false);
    }
  }

  function viewDocument(document) {
    setCurrentDocument(document);
    setCurrentTransaction(transaction);
    navigate(
      `/transactions?transaction=${transactionId}&document=${document.uuid}`
    );
  }

  return (
    <>
      {hasFiles ? (
        <>
          {documents.map((file, index) => (
            <div key={index}>
              <Card className='mb-2 cardWhite h-70 text-left'>
                <Card.Body className={styles.cardBody}>
                  <span className='badge badge-info fw-600'>PDF</span>
                  <Card.Text className={styles.cardBodyText}>
                    <p className='mb-0 fw-700 fs-14'>{file.title}</p>
                    <span className='color-lightgray fs-12 fw-700'>
                      {file?.signed ? (
                        <span className='color-lightgray fs-12 fw-700'>
                          Signed by {transaction?.signed}/{transaction?.total}{' '}
                          signers
                        </span>
                      ) : (
                        <span>
                          <img
                            src={Check}
                            alt='check'
                            className={styles.checkIcon}
                          />
                          Uploaded
                        </span>
                      )}
                    </span>
                  </Card.Text>
                  {file?.signed ? (
                    <div
                      onClick={(e) => viewDocument(file)}
                      className={styles.viewFileBtn}
                    >
                      <ChevronRightIcon />
                    </div>
                  ) : (
                    <div className={styles.deleteDoc}>
                      <img
                        src={Cross}
                        alt='delete'
                        className={styles.crossIcon}
                        onClick={(e) => deleteTransactionDocument(file)}
                      />
                    </div>
                  )}
                </Card.Body>
              </Card>
            </div>
          ))}
          {transactionFiles.map((file, index) => (
            <div key={index}>
              <Card className='mb-2 cardWhite h-70 text-left'>
                <Card.Body className={styles.cardBody}>
                  <span className='badge badge-info fw-600'>PDF</span>
                  <Card.Text className={styles.cardBodyText}>
                    <p className='mb-0 fw-700 fs-14'>{file.name}</p>
                    {showProgressBar && (
                      <>
                        <ProgressLine
                          visualParts={[
                            {
                              percentage: '100%',
                              color: 'black'
                            }
                          ]}
                        />
                        <div className={styles.progressSpacer} />
                      </>
                    )}

                    {showText && (
                      <span className='color-lightgray fs-12 fw-700'>
                        <img
                          src={Check}
                          alt='check'
                          className={styles.checkIcon}
                        />
                        Uploaded
                      </span>
                    )}
                  </Card.Text>
                  <div className={styles.deleteDoc}>
                    <img
                      src={Cross}
                      alt='delete'
                      className={styles.crossIcon}
                      onClick={(e) => deleteDocument(file)}
                    />
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
        </>
      ) : (
        <>
          <div className='text-center'>
            <p className='fs-14 fw-500 mb-0'>
              Please upload the File/s you wish
              <br />
              to send for signing.
            </p>
          </div>
        </>
      )}
      <div className={styles.browseFilesContainer}>
        <img
          src={CloudUpload}
          alt='Cloud Upload'
          className={styles.uploadCloudIcon}
        />
        <p className='fs-16 fw-700 mt-2'>Upload files here</p>
        <Button
          variant={`${hasFiles ? 'outline-dark' : 'dark'}`}
          className='btn-rounded btn-sm p-t-10 p-b-10 p-l-30 p-r-30 no-box-shadow'
          type='button'
          onClick={() => {
            selectFiles(
              { multiple: true, accept: '.pdf,application/pdf' },
              (files) => {
                // Note callback return an array
                files.map(({ source, name, size, file }) => {
                  console.log('The File');
                  console.log({ source, name, size, file });
                });
                // Todo: Upload to cloud.
              }
            );
          }}
        >
          <span className='fs-14 fw-700'>Browse</span>
        </Button>
      </div>

      {hasFiles && props.transactionName ? (
        <div className={styles.spacer}></div>
      ) : null}

      {hasFiles && props.transactionName ? (
        <>
          <div className={styles.footer}>
            <Row className='m0'>
              <Col
                lg={{ span: 4, offset: 4 }}
                md={{ span: 6, offset: 3 }}
                className={styles.footerInner}
              >
                {showLoader ? (
                  <div className='mb-4'>
                    <SpinnerAnimation />
                  </div>
                ) : (
                  <Button
                    variant='dark'
                    disabled={!hasFiles}
                    className='btn-block btn-rounded mb-4 fw-700'
                    onClick={(e) => saveTransaction()}
                  >
                    Continue
                  </Button>
                )}
                <span
                  className='m0 fw-700 fs-16 pointer'
                  onClick={() => navigate('/')}
                >
                  Cancel
                </span>
              </Col>
            </Row>
          </div>
        </>
      ) : null}
    </>
  );
}
