import React from 'react';
import styles from './styles.module.css';
import { motion } from 'framer-motion';

export default function SpinnerAnimation() {
  return (
    <div className='d-flex align-items-center h-54'>
      <motion.div
        className={styles.dotAnimation}
        animate={{ x: [-20, 20, -20, 20] }}
        transition={{ ease: 'easeOut', duration: 2, repeat: Infinity }}
      />
      <motion.div
        className={styles.dotAnimation}
        animate={{ x: [20, -20, 20, -20] }}
        transition={{ ease: 'easeOut', duration: 2, repeat: Infinity }}
      />
    </div>
  );
}
