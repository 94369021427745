import DewlyLogo from 'assets/images/dewly-logo.svg';
import { Document } from 'components';
import { WhiteChevronRightIcon } from 'components/Icons';
import SpinnerAnimation from 'components/Spinners/SpinnerAnimation/SpinnerAnimation';
import SquareLogoAnimate from 'components/SquareLogoAnimate/SquareLogoAnimate';
import appContext from 'context/app-context';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { createSearchParams, useNavigate } from 'react-router-dom';
import DocumentList from '../Finalisation/DocumentList/DocumentList';
import styles from './styles.module.css';
// Framer Motion animation
const Container = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.5
    }
  }
};

export default function PageOne(props) {
  const navigate = useNavigate();
  const [userType, setUserType] = useState('new');
  const [loader, setLoader] = useState(false);
  const {
    user,
    getTransaction,
    getDocuments,
    getSignatory,
    token,
    setContinuePage
  } = useContext(appContext);

  let url = new URL(window.location.href);
  let newPath = url.pathname + url.search;
  let params = new URLSearchParams(url.search);
  let transactionUuid = params.get('transaction');
  let signatory = params.get('signatory');
  let self = params.get('self');
  let signType = params.get('sign_type');

  function ContinueToEmail() {
    navigate({
      pathname: '/continue'
    });
    setContinuePage(0);
  }

  function ContinueToDashboard() {
    if (window.location.href.includes('sign_type=existing')) {
      navigate({
        pathname: '/'
      });
    }
  }

  function goToContinue(e) {
    e.preventDefault();
    // sessionStorage.removeItem('token');
    setUserType(signType);
    getTransaction(transactionUuid);
    getDocuments(transactionUuid, self, signatory);
    getSignatory(transactionUuid, self, signatory);

    if (signatory && !sessionStorage.getItem('token')) {
      navigate({
        pathname: '/continue',
        search: `?${createSearchParams(
          'transaction=' +
            transactionUuid +
            '&sign_type=' +
            signType +
            '&signatory=' +
            signatory +
            '&self=' +
            self
        )}`
      });
    } else {
      window.location.href = newPath.replace('sign', 'continue');
    }
  }

  useEffect(() => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    console.log(user, '=user');
    let signType = params.get('sign_type');
    if (sessionStorage.getItem('token') && signType !== 'new') {
      setUserType('logged');
    } else {
      setUserType(signType);
    }

    setTimeout(() => {
      setLoader(true);
    }, 1500);
  }, []);

  function cancel() {
    navigate('/');
  }

  const cancelBtn = () => {
    if (
      !window.location.href.includes('transaction') ||
      window.location.href.includes('sign_type=new') ||
      window.location.href.includes('sign_type=existing')
    ) {
      return (
        <>
          <span onClick={cancel} className='m0 fw-700 fs-16 pointer'>
            Cancel
          </span>
        </>
      );
    } else {
      return;
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page '
        >
          {!loader ? (
            <>
              <Row>
                <Col lg={{ span: 4, offset: 4 }} md={{ span: 6, offset: 3 }}>
                  <div className={styles.fullscreenSpinner}>
                    <div className='center-content'>
                      <SpinnerAnimation />
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <div className={styles.centerContent}>
              <div className='pt-5'>
                {props.business?.logo ? '' : <SquareLogoAnimate />}
              </div>
              <motion.div
                variants={Container}
                initial='hidden'
                animate='visible'
              >
                <div className='p-b-100'>
                  <div className='text-center mb-2'>
                    {props.business?.logo ? (
                      <img
                        src={props.business?.logo}
                        className={styles.photo}
                        width={200}
                        height={200}
                        alt='Logo'
                      />
                    ) : (
                      ''
                    )}
                    <p className='fs-18 fw-800 pt-5'>
                      Hello{' '}
                      <>
                        {props.signatory?.first_name || user?.first_name || ''}
                      </>{' '}
                      <>{props.signatory?.last_name}</>,{' '}
                    </p>
                    <motion.div
                      animate={{ y: -10 }}
                      transition={{ delay: 1.8 }}
                    >
                      {props.business?.name ? (
                        <p className='fw-500 fs-14 m0 pb-4'>
                          Your {props.documents?.length > 1 ? 'files' : 'file'}{' '}
                          from {props.business?.name}
                          <br />
                          {props.documents.length > 1 ? 'are' : 'is'} ready for
                          you to sign
                        </p>
                      ) : (
                        <p className='fw-500 fs-14 m0 pb-5'>
                          {props.documents.length > 0 ? (
                            <>
                              {props.sender?.first_name ? (
                                <>
                                  {props.sender?.first_name}{' '}
                                  <>{props.sender?.last_name}</>
                                </>
                              ) : (
                                <span className='lowercase'>
                                  {props.sender?.email}
                                </span>
                              )}
                              ,
                              <br />
                              would like you to <strong>sign</strong>
                            </>
                          ) : (
                            <>
                              <div>
                                The transaction has been removed by the author,
                                and can no longer be accessed.
                                <br />
                                <br />
                                Please contact{' '}
                                {props.sender?.first_name ? (
                                  <>
                                    {props.sender?.first_name}{' '}
                                    <>{props.sender?.last_name}</>
                                  </>
                                ) : (
                                  <span className='lowercase'>
                                    {props.sender?.email}
                                  </span>
                                )}{' '}
                                for more information.
                                <br />
                                <br />
                                <Button
                                  variant='dark'
                                  className='btn-rounded btn-block blackButton'
                                  onClick={ContinueToEmail}
                                >
                                  <span className='fs-14 fw-700'>Continue</span>
                                </Button>
                              </div>
                            </>
                          )}
                        </p>
                      )}
                    </motion.div>
                    <p className={styles.transactionTitle}>
                      {props.transaction?.name}
                    </p>
                  </div>
                  <div className='p-3'>
                    {userType === 'logged' ||
                    window.location.href.includes('return_to_signing=true')
                      ? // || window.location.href.includes('sign_type=existing')
                        props.documents.map((document) => {
                          return (
                            <Document
                              key={document.uuid}
                              title={document.title}
                              document={document}
                              signType={userType}
                            />
                          );
                        })
                      : props.documents.map((document) => {
                          return (
                            <div className='text-center'>
                              <DocumentList
                                key={document.uuid}
                                title={document.title}
                              />
                            </div>
                          );
                        })}
                  </div>
                  {!token ? (
                    // (userType === 'new' &&
                    //   !window.location.href.includes('return_to_signing=true')) ||
                    // (userType === 'existing' &&
                    //   window.location.href.includes('return_to_signing=true'))
                    <div className='text-center p-t-30'>
                      <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: [0, 0, 0, 1] }}
                        transition={{ duration: 3 }}
                      >
                        <Button
                          onClick={(e) => goToContinue(e)}
                          className={`${styles.btnFooter} btn btn-dark rounded-circle btn-md-rounded pulse-animate`}
                        >
                          <span
                            className='p-relative'
                            style={{ right: '-2px' }}
                          >
                            <WhiteChevronRightIcon />
                          </span>
                        </Button>
                      </motion.div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>

                <div className={styles.logoContainer}>
                  <img
                    onClick={ContinueToDashboard}
                    src={DewlyLogo}
                    alt='dewly'
                    style={{ cursor: 'pointer' }}
                  />
                </div>
                {/* <div className={styles.cancelBtn}>{cancelBtn()}</div> */}
              </motion.div>
            </div>
          )}
        </Col>
      </Row>
    </motion.div>
  );
}
