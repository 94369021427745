import SpinnerAnimation from 'components/Spinners/SpinnerAnimation/SpinnerAnimation';
import appContext from 'context/app-context';
import React, { useContext, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import styles from './styles.module.css';

export default function EmailConfirmation({
  setContinueFromEmail,
  setContinueFromConfirmation
}) {
  const { setContinuePage, email } = useContext(appContext);
  const [showLoader, setShowLoader] = useState(false);

  // const [terms, setTerms] = useState(false);

  const welcomeMessages = () => {
    return (
      <>
        <p className='mb-3 fw-700 fs-16'>
          You are about to create a new account for
        </p>
        <p className='fw-600 fs-16 mb-0 p-b-60 color-lightgray'>{email}</p>
      </>
    );
  };

  const goBack = () => {
    setContinueFromEmail(false);
  };

  const confirm = () => {
    setContinueFromEmail(false);
    setContinueFromConfirmation(true);
  };

  return (
    <>
      <div className={`${styles.footerWhite}`}>
        <Row className='m0 pt-4'>
          <Col
            lg={{ span: 4, offset: 4 }}
            md={{ span: 8, offset: 2 }}
            xs={{ span: 12, offset: 0 }}
            className={`${styles.footerInner}`}
          >
            <div className='text-center m-t-20'>{welcomeMessages()}</div>
            <div>
              <div className='text-center ps-3 pe-3'>
                {showLoader ? (
                  <SpinnerAnimation />
                ) : (
                  <Button
                    variant='dark'
                    className='btn-rounded btn-block'
                    onClick={confirm}
                    // disabled={!terms}
                  >
                    <span className='fs-16 fw-700'>Proceed</span>
                  </Button>
                )}
              </div>
              <div className='text-center ps-3 pe-3 mt-2'>
                <Button
                  variant='outline-dark'
                  className='btn-rounded btn-block mt-2 fade-in'
                  onClick={(e) => goBack()}
                >
                  <span className='pointer fs-16 fw-700'>Cancel</span>
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
