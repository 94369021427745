import DewlyLogo from 'assets/images/dewly-logo.svg';
import SpinnerAnimation from 'components/Spinners/SpinnerAnimation/SpinnerAnimation';
import SquareLogoAnimate from 'components/SquareLogoAnimate/SquareLogoAnimate';
import appContext from 'context/app-context';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useContext, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import styles from './styles.module.css';

export default function Success() {
  const { setSendingPage, currentTransaction } = useContext(appContext);
  const navigate = useNavigate();
  const [showLogo, setShowLogo] = useState(false);
  const [showProgressBar, setProgressBar] = useState(true);
  setTimeout(() => {
    setShowLogo(true);
    setProgressBar(false);
  }, 2000);
  const openTransaction = async (currentTransaction) => {
    window.location.href = `/transactions?transaction=${currentTransaction.id}`;
  };
  setTimeout(() => {
    window.location.replace('/');
  }, 5000);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page'
        >
          <div className={styles.content}>
            <AnimatePresence>
              {showProgressBar && (
                <motion.div exit={{ opacity: 0 }}>
                  <SpinnerAnimation />
                  <p className='pt-1 m0 fw-800 fs-18'>Sending</p>
                </motion.div>
              )}
            </AnimatePresence>

            <AnimatePresence>
              {showLogo && (
                <motion.div exit={{ opacity: 0 }}>
                  <SquareLogoAnimate />
                  <p className='pt-3 m0 fw-800 fs-18'>
                    Your files have been sent
                    <br />
                    for signing
                  </p>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <div className={styles.footer}>
            <div className={styles.logoContainer}>
              <img src={DewlyLogo} alt='dewly' />
            </div>
            <Button
              variant='dark'
              className='btn-block btn-rounded mb-3 fw-700'
              onClick={(e) => openTransaction(currentTransaction)}
            >
              View Transaction
            </Button>

            <Button
              variant='outline-dark'
              className='btn-block btn-rounded fw-700'
              onClick={(e) => (setSendingPage(0), navigate('/'))}
            >
              Go home
            </Button>
          </div>
        </Col>
      </Row>
    </motion.div>
  );
}
