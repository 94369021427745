import React from 'react';
import './switch.css';

const Switch = ({ isOn, handleToggle, left, right }) => {
  return (
    <label style={{ background: isOn }} className='react-switch'>
      <input
        checked={isOn}
        onChange={handleToggle}
        className='react-switch-checkbox'
        type='checkbox'
      />
      <div className='react-switch-button' />
      <div className='react-switch-labels'>
        <span>{left}</span>
        <span>{right}</span>
      </div>
    </label>
  );
};

export default Switch;
