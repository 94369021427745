import * as Sentry from '@sentry/react';
import PlusCircle from 'assets/images/plus-circle.svg';
import SpinnerAnimation from 'components/Spinners/SpinnerAnimation/SpinnerAnimation';
import appContext from 'context/app-context';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.css';

// PDF styles
import '@react-pdf-viewer/core/lib/styles/index.css';
import axios from 'axios';

const CreateEditAcknowledgement = (props) => {
  const { setTransactionPage, currentDocument, currentTransaction, user } =
    useContext(appContext);
  const navigate = useNavigate();

  const [showAcknowledgementField, setShowAcknowledgementField] =
    useState(false);
  const [showApplicable, setShowApplicable] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [form, showForm] = useState(true);
  const [signatories, setSignatories] = useState([]);
  const [acknowledgement, setAcknowledgement] = useState(null);
  const [userIds, setUserIds] = useState([]);
  const [acknoledgementsSignatoriesIds, setAcknoledgementsSignatoriesIds] =
    useState([]);
  const [
    acknoledgementsSignatoriesAcceptedIds,
    setAcknoledgementsSignatoriesAcceptedIds
  ] = useState([]);

  const [acknoledgementsSignatories, setAcknoledgementsSignatories] = useState(
    []
  );
  const [documentAcknoledgements, setDocumentAcknoledgements] = useState([]);
  const [showEditAcknowledgement, setShowEditAcknowledgement] = useState(false);
  const [showConfirmPopup, setShowConfirmPopup] = useState(false);
  const [acknowledgementAccepted, setAcknowledgementAccepted] = useState(false);

  useEffect(() => {
    getSignatories();
  }, [currentTransaction, props.acknowledgementIndex]);

  useEffect(() => {
    if (props.isEdit) {
      editAcknowledgement(props.acknowledgementIndex);
    }
  }, [documentAcknoledgements, props.acknowledgementIndex, signatories]);

  /*********************
   * HTTP CALLS
   ********************/

  // HTTP get signatories
  async function getSignatories() {
    try {
      const { data } = await axios.get(
        `/transactions/${currentTransaction.id}/signatories`
      );
      setSignatories(data.signatories);
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  // HTTP add new acknowledgement
  async function saveAcknowledgements() {
    setShowLoader(true);
    try {
      await axios.post(`/acknowledgement/${currentDocument.uuid}`, {
        support_text: acknowledgement,
        signatories: userIds,
        transactionUuid: currentTransaction.id
      });
      closePopup();

      setShowLoader(false);

      // sendEmail(userIds);

      props.previousPage();

      props.listAcknowledgements();
    } catch (e) {
      Sentry.captureException(e);
      setShowLoader(false);
      closePopup();
    }
  }

  // HTTP add or Remove Signatory
  async function addOrDeleteSignatory(action, userDetails) {
    setShowLoader(true);
    try {
      await axios.post(`/acknowledgement/edit-signatory`, {
        action: action,
        user_id: userDetails.id,
        acknowledgement_id: props.selectedAcknowledgement?.id,
        document_id: currentDocument.id,
        email: userDetails.email
      });

      setShowLoader(false);
    } catch (e) {
      Sentry.captureException(e);
      setShowLoader(false);
      closePopup();
    }
  }

  // HTTP update acknowledgement
  async function updateAcknowledgement(acknowledgement_id) {
    setShowLoader(true);
    try {
      await axios.put(`/acknowledgement/update/${acknowledgement_id}`, {
        support_text: acknowledgement
          ? acknowledgement
          : props.selectedAcknowledgement.support_text,
        signatories: userIds
      });

      closePopup();
      setShowLoader(false);

      // sendEmail(userIds);
      props.previousPage();
    } catch (e) {
      Sentry.captureException(e);
      setShowLoader(false);
      closePopup();
    }
  }

  // HTTP delete acknowledgement
  async function deleteAcknowledgement(acknowledgement_id) {
    setShowLoader(true);
    try {
      await axios.delete(`/acknowledgement/delete/${acknowledgement_id}`);
      closePopup();
      setShowLoader(false);
      props.previousPage();
    } catch (e) {
      Sentry.captureException(e);
      closePopup();
      setShowLoader(false);
    }
  }

  const sendEmail = async (userIds) => {
    try {
      setShowLoader(true);
      const { data } = await axios.post(
        `transactions/${currentTransaction.id}/signatories/send-acknowledgement`,
        {
          userIds: userIds
        }
      );

      if (data.success) {
        sessionStorage.removeItem('transactionId');
        sessionStorage.removeItem('transactionName');
        setShowLoader(false);
        // setSendingPage(4);
      }
    } catch (e) {
      setShowLoader(false);
      console.log(e);
      Sentry.captureException(e);
    }
  };

  /*********************
   * GENERAL FUNCTIONS
   ********************/
  // Update user ids array with selected signatories
  function updateUsers(userId, remove = false) {
    let user_ids = userIds.length ? userIds : [];
    if (!remove) {
      user_ids = user_ids.filter((id) => id !== userId);
      setUserIds(user_ids);
      return;
    }

    setUserIds([...new Set(user_ids.concat([userId]))]);
  }

  function updateAcknowledgementSignatoryIds(userId, value) {
    // let selectedUser = [];
    signatories.map((item, index) => {
      if (item.id == userId) {
        let action = 'delete';
        if (value) {
          action = 'add';
        }
        return addOrDeleteSignatory(action, item);
      }
    });
  }

  // Show applicable to fields
  function applicable() {
    setShowAcknowledgementField(true);
    showForm(false);
    setShowApplicable(true);
  }

  // Show edit bottom popup
  function editAcknowledgement(index) {
    let acknoledgementsSignatoriesIdsArr = [];
    let acknowledgementSignatoriesArr = [];
    let hasAcceptedAcknowledgement = [];
    // Create array of signatories
    props.selectedAcknowledgement?.signatories?.map((item, index) => {
      acknoledgementsSignatoriesIdsArr.push(item.user_id);
      acknowledgementSignatoriesArr.push(item.user_id);

      if (item.has_accepted == 1) {
        hasAcceptedAcknowledgement.push(item.user_id);
        setAcknowledgementAccepted(true);
      }
    });

    setAcknoledgementsSignatoriesIds(acknowledgementSignatoriesArr);
    setAcknoledgementsSignatoriesAcceptedIds(hasAcceptedAcknowledgement);
  }

  // Count how many people accepted and total
  function countSignatories(array, type) {
    if (type == 'signed') {
      let count = 0;
      array.forEach((element) => {
        if (element.has_accepted == 1) {
          count++;
        }
      });
      return count;
    }

    if (type == 'total') {
      return array.length;
    }
  }

  // Close bottom popup
  function closePopup() {
    showForm(true);
    setShowAcknowledgementField(false);
    setShowApplicable(false);
    setShowEditAcknowledgement(false);
    setShowConfirmPopup(false);
    setUserIds([]);
    setAcknoledgementsSignatoriesIds([]);
  }

  // Open Confirm modal on delete
  function openConfirmPopup() {
    setShowConfirmPopup(true);
  }

  //Close Confirm modal on delete
  function closeConfirmPopup() {
    setShowConfirmPopup(false);
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      {/* Confirm Delete Pop up */}
      {showConfirmPopup && (
        <div className='fullscreen-confirm-popup-container fade-in'>
          <div className='confirm-popup-content'>
            <h6 className='mt-4'>Are you sure you want to delete?</h6>

            {showLoader ? (
              <div className='p-b-50'>
                <SpinnerAnimation />
              </div>
            ) : (
              <>
                <Button
                  variant='dark'
                  onClick={() =>
                    deleteAcknowledgement(props.selectedAcknowledgement?.id)
                  }
                  className='btn-block btn-rounded mt-4 fw-700'
                >
                  <span className='fs-16'>Yes</span>
                </Button>
                <Button
                  variant='outline'
                  className='btn-rounded btn-block mt-2'
                  onClick={() => setShowConfirmPopup()}
                >
                  <span className='pointer fs-16 fw-700'>Cancel</span>
                </Button>
              </>
            )}
          </div>
        </div>
      )}

      {/* ADD ACKNOWLEDGEMENT DETAILS */}
      <>
        <div onClick={closePopup} className={`overlay`}></div>

        <div
          className={`${styles.footerAnimate} slide-up acknowledgements-edit ${
            showAcknowledgementField ? 'shown-div' : 'hide-div'
          }`}
        >
          <Row className='m0'>
            <Col
              lg={{ span: 4, offset: 4 }}
              md={{ span: 6, offset: 3 }}
              className={styles.addSignee}
            >
              {/* ADD NEW ACKNOWLEDGEMENTS */}
              {props.isNew && (
                <>
                  {form && (
                    <>
                      <p className='mb-4 pt-4 fs-16 fw-700'>
                        Insert the information you would like the signer
                        <br className={styles.customBreakpoint} /> to
                        acknowledge and accept
                      </p>
                      <Form.Group className='mb-4'>
                        <Form.Control
                          as='textarea'
                          rows={3}
                          className={styles.input}
                          defaultValue={''}
                          onChange={(e) => setAcknowledgement(e.target.value)}
                        />
                      </Form.Group>
                      <Button
                        variant='dark'
                        onClick={applicable}
                        className='btn-block btn-rounded'
                        disabled={acknowledgement ? '' : 'true'}
                      >
                        <span className='fs-16 fw-700'>Continue</span>
                      </Button>
                      <Button
                        variant='outline'
                        className='btn-rounded btn-block mt-2'
                        onClick={() => (closePopup, props.previousPage())}
                      >
                        <span className='fs-16 fw-700'>Cancel</span>
                      </Button>
                    </>
                  )}

                  {/* applicable to */}
                  {showApplicable && (
                    <>
                      <p className='mb-4'>Applicable to</p>
                      {signatories?.map((user, index) => {
                        return (
                          <div key={index} className={styles.usersContainer}>
                            <div className={styles.user}>
                              <div className={styles.initialsContainer}>
                                <p className={`${styles.center}`}>
                                  {user?.first_name ? (
                                    <>
                                      {user?.first_name?.charAt(0)}
                                      {user?.last_name?.charAt(0)}
                                    </>
                                  ) : (
                                    <>{user?.email?.charAt(0)}</>
                                  )}
                                </p>
                              </div>
                              {/* <p className={styles.userName}>
                                <span className='fw-700 pt-3 pb-3 pe-3 pointer'>
                                  {user?.first_name ? (
                                    <>
                                      {' '}
                                      {user?.first_name} {user?.last_name}{' '}
                                    </>
                                  ) : (
                                    <>{user?.email}</>
                                  )}
                                </span>
                              </p> */}
                              <Form.Check
                                type='checkbox'
                                onChange={(e) =>
                                  updateUsers(user.id, e.target.checked)
                                }
                                reverse
                                inline
                                label={
                                  user?.first_name
                                    ? user?.first_name + ' ' + user?.last_name
                                    : user?.email
                                }
                                id={user.id}
                                className={styles.userCheck}
                              />
                            </div>
                          </div>
                        );
                      })}

                      {showLoader ? (
                        <div className='p-b-50 p-t-60'>
                          <SpinnerAnimation />
                        </div>
                      ) : (
                        <>
                          <Button
                            variant='dark'
                            onClick={saveAcknowledgements}
                            className='btn-block btn-rounded mt-5'
                            disabled={userIds.length > 0 ? '' : 'true'}
                          >
                            <span className='fs-16 fw-700'>Continue</span>
                          </Button>
                          <Button
                            variant='outline'
                            className='btn-rounded btn-block mt-2'
                            onClick={() => (closePopup, props.previousPage())}
                          >
                            <span className='pointer fs-16 fw-700'>Cancel</span>
                          </Button>
                        </>
                      )}
                    </>
                  )}
                </>
              )}

              {/* EDIT ACKNOWLEDGEMENTS */}
              {props.isEdit && (
                <>
                  {/* Paragraph */}
                  <p className='mb-4 pt-4 fs-16 fw-700'>
                    Insert the information you would like the signer
                    <br className={styles.customBreakpoint} /> to acknowledge
                    and accept
                  </p>
                  <Form.Group className='mb-4 fade-in'>
                    <Form.Control
                      as='textarea'
                      disabled={acknowledgementAccepted ? true : false}
                      rows={3}
                      className={styles.input}
                      defaultValue={props.selectedAcknowledgement?.support_text}
                      onChange={(e) => setAcknowledgement(e.target.value)}
                    />
                  </Form.Group>
                  {/* Applicable to */}
                  <p className='mb-4'>Applicable to</p>

                  {signatories?.map((user, index) => {
                    //{props.selectedAcknowledgement?.signatories?.map((user, index) => {
                    return (
                      <div
                        key={index}
                        className={`applicable-to fade-in ${styles.usersContainer}`}
                      >
                        <div className={styles.user}>
                          <div className={styles.initialsContainer}>
                            <p className={`${styles.center}`}>
                              {user?.first_name ? (
                                <>
                                  {user?.first_name?.charAt(0)}
                                  {user?.last_name?.charAt(0)}
                                </>
                              ) : (
                                <>{user?.email?.charAt(0)}</>
                              )}
                            </p>
                          </div>
                          {/* <p
                            className={`${styles.userName} ${
                              user.has_accepted == 1 ? 'accepted' : ''
                            }`}
                          >
                            <span className='fw-700 pt-3 pb-3 pe-3 pointer'>
                              {user?.first_name ? (
                                <>
                                  {' '}
                                  {user?.first_name} {user?.last_name}{' '}
                                </>
                              ) : (
                                <>{user?.email}</>
                              )}
                            </span>
                          </p> */}

                          {/* {showLoader ? (
                                  <div className='loader p-b-50'>
                                    <SpinnerAnimation />
                                  </div>
                                ) : ( */}
                          <Form.Check
                            type='checkbox'
                            className={styles.labelMediumWeight}
                            defaultChecked={
                              acknoledgementsSignatoriesIds?.includes(user.id)
                                ? true
                                : false
                            }
                            disabled={
                              acknoledgementsSignatoriesAcceptedIds?.includes(
                                user.id
                              )
                                ? 'disabled'
                                : ''
                            }
                            value={user.id}
                            onChange={(e) =>
                              updateAcknowledgementSignatoryIds(
                                user.id,
                                e.target.checked
                              )
                            }
                            reverse
                            inline
                            label={
                              user?.first_name
                                ? user?.first_name + ' ' + user?.last_name
                                : user?.email
                            }
                            id={user.id}
                          />
                          {/* )} */}
                        </div>
                      </div>
                    );
                  })}

                  {/* Buttons */}

                  {showLoader ? (
                    <div className='p-b-50'>
                      <SpinnerAnimation />
                    </div>
                  ) : (
                    <>
                      <Button
                        variant='dark'
                        onClick={() =>
                          updateAcknowledgement(
                            props.selectedAcknowledgement?.id
                          )
                        }
                        className='btn-block btn-rounded mt-5 fade-in fw-700'
                      >
                        <span className='fs-16'>Save</span>
                      </Button>
                      {!acknowledgementAccepted && (
                        <Button
                          variant='outline-dark'
                          className='btn-rounded btn-block mt-2 fade-in'
                          onClick={openConfirmPopup}
                        >
                          <span className='fs-16 fw-700'>Delete</span>
                        </Button>
                      )}
                      <Button
                        variant='outline'
                        className='btn-rounded btn-block mt-2 fade-in'
                        onClick={() => (closePopup, props.previousPage())}
                      >
                        <span className='pointer fs-16 fw-700'>Back</span>
                      </Button>
                    </>
                  )}
                </>
              )}
            </Col>
          </Row>
        </div>
      </>
    </motion.div>
  );
};

export default CreateEditAcknowledgement;
