import * as Sentry from '@sentry/react';
import axios from 'axios';
import SpinnerAnimation from 'components/Spinners/SpinnerAnimation/SpinnerAnimation';
import SquareLogoAnimate from 'components/SquareLogoAnimate/SquareLogoAnimate';
import appContext from 'context/app-context';
import React, { useContext, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styles from './styles.module.css';

export default function Phone(props) {
  const { setContinuePage, user, getTransaction, getDocuments, getSignatory } =
    useContext(appContext);
  const navigate = useNavigate();

  const [error, setError] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [, setUserType] = useState('new');
  const [cell, setCell] = useState(user.cell);

  const savePhoneNumber = async () => {
    setShowLoader(true);
    if (!cell || cell.length < 10) {
      setError('Please provide a phone number.');
      window.scrollTo(0, 0);
      setShowLoader(false);
      return;
    } else {
      setError('');
    }

    try {
      const { data } = await axios.put(`add-cell/${user.uuid}`, {
        cell: cell ? cell.replace('+', '') : cell
      });
      if (data.success) {
        let url = new URL(window.location.href);
        let params = new URLSearchParams(url.search);
        let transactionUuid = params.get('transaction');
        let signatory = params.get('signatory') ?? '';
        let self = params.get('self');
        let signType = params.get('sign_type');
        setUserType(signType);
        getTransaction(transactionUuid);
        getDocuments(transactionUuid, self, signatory);
        getSignatory(transactionUuid, self, signatory);

        if (signType === 'new') {
          navigate({
            pathname: '/sign',
            search: `?${createSearchParams(
              'return_to_signing=true' +
                '&transaction=' +
                transactionUuid +
                '&sign_type=' +
                signType +
                '&signatory=' +
                signatory +
                '&self=' +
                self
            )}`
          });
        } else {
          setContinuePage(3);
        }
        setShowLoader(false);
      }
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
      setErrorMessage(e.response.data.message);
      setShowLoader(false);
    }
  };

  const welcomeMessages = () => {
    if (user?.first_name) {
      return (
        <>
          <p className='pb-5 pt-2 fw-700 fs-16'>
            <strong>Hello, {user?.first_name}</strong>
            <br />
            <br />
            please provide your mobile number.
          </p>
        </>
      );
    } else {
      return (
        <>
          <p className='pb-5 pt-2 fw-700 fs-16'>
            Please provide your mobile number.
            <br />
          </p>
        </>
      );
    }
  };

  return (
    <>
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page p-relative'
        >
          <div className={styles.centerContent}>
            <div className='mt-4 pt-4 pb-4'>
              <SquareLogoAnimate />
            </div>
            <div className='text-center'>
              {welcomeMessages()}
              <Form onSubmit={savePhoneNumber}>
                <Form.Group className='mb-3 ps-3 pe-3'>
                  <div className='text-center pb-3 fw-700'>
                    <label className={styles.label}>Mobile</label>
                  </div>
                  <PhoneInput
                    className='phoneInput phone-number-input'
                    defaultCountry='ZA'
                    value={cell}
                    onChange={(e) => setCell(e)}
                  />
                  {error ? <small className={styles.error}>{error}</small> : ''}
                </Form.Group>
                {errorMessage && (
                  <p className='color-red px-3 fs-14'>{errorMessage}</p>
                )}

                <br />
                <div className='text-center ps-3 pe-3'>
                  {showLoader ? (
                    <SpinnerAnimation />
                  ) : (
                    <Button
                      variant='dark'
                      className='btn-rounded btn-block'
                      onClick={(e) => savePhoneNumber(e)}
                      disabled={!cell}
                    >
                      <span className='fs-14 fw-700'>Continue</span>
                    </Button>
                  )}
                </div>
                <div style={{ marginTop: '150px', textAlign: 'center' }}>
                  <img
                    src='/static/media/dewly-logo.95b074ed119097ec1853a1fdc0320f6b.svg'
                    alt='dewly'
                  />
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}
