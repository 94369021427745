import React, { useEffect } from 'react';
import styles from './styles.module.css';
import DewlyLogo from 'assets/images/dewly-logo.svg';
import FirstTimeUserPhoto from 'assets/images/Dashboard_Image.svg';
import WhiteLogo from 'assets/images/white-logo-sign.svg';
import { useNavigate } from 'react-router-dom';

export default function FirstTimeUser() {
  const navigate = useNavigate();

  useEffect(() => {
    if (!sessionStorage.getItem('token')) {
      navigate('/continue');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={styles.main}>
        <div
          className={styles.profilePic}
          style={{
            backgroundImage: `url(${FirstTimeUserPhoto})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat'
          }}
        ></div>
        <div className='p-relative'>
          <img src={WhiteLogo} alt='dewly' className={styles.whiteLogo} />
        </div>
        <div className='text-center mt-5 ps-3 pe-3'>
          <p className='fw-700 fs-16'>
            Why sign files with yesterday’s tools,
            <br />
            when the tools of tomorrow are here, today?
          </p>
          {/* <Button
            variant='outline-dark'
            className='btn-rounded btn-block mb-3 mt-4'
          >
            <span className='fs-16 fw-600'>
              How <strong className='fw-800'>dewly</strong> grows businesses
            </span>
          </Button>
          <Button variant='outline-dark' className='btn-rounded btn-block mb-3'>
            <span className='fs-16 fw-600'>
              Get <strong className='fw-800'>dewly</strong> for your business
            </span>
          </Button> */}
        </div>
        <div className='divider mt-5 mb-5' />
        <div className={styles.logoContainer}>
          <img src={DewlyLogo} alt='dewly' />
        </div>
      </div>
    </>
  );
}
