import { useContext, useEffect, useState, useRef } from 'react';
import { motion } from 'framer-motion';
import styles from './styles.module.css';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { WhiteChevronRightIcon } from 'components';
import SpinnerAnimation from 'components/Spinners/SpinnerAnimation/SpinnerAnimation';
import appContext from 'context/app-context';
import Identification from 'assets/images/identification.svg';
import GoBackHeader from 'components/GoBackHeader/GoBackHeader';
import axios from 'axios';
import * as Sentry from '@sentry/react';

export default function IDVerification() {
  const { setProfilePage, idNumberVerified, setIdNumber, user } =
    useContext(appContext);
  const [idNumber, setId] = useState('');
  const [error, setError] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [country, setCountry] = useState('');
  const IDInput = useRef();

  useEffect(() => {
    if (IDInput.current) IDInput.current.focus();
  }, [IDInput]);

  const verifyId = async () => {
    setShowLoader(true);
    try {
      const { data } = await axios.post(`verify-id`, {
        id_number: idNumber,
        //TODO: add country code
        code: 'ZA',
        country: country,
        email: user?.email
      });

      setShowLoader(false);
      if (data.success === true) {
        setProfilePage(5);
        setIdNumber(idNumber);
        console.log(data);
      } else {
        setError('Id number verification failed.');
        console.log('id not verified', idNumber, idNumberVerified);
      }
    } catch (e) {
      setShowLoader(false);
      setError('Id number verification failed.');
      console.log('id not verified', idNumber, idNumberVerified);
      Sentry.captureException(e);
    }
  };

  function previousPage() {
    setProfilePage(0);
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Row className='m0'>
        <GoBackHeader
          previousPage={previousPage}
          pageName={'ID Verification'}
        />
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page'
        >
          <div className={styles.main}>
            <div className='text-center pt-5'>
              <div className='pb-2'>
                <img src={Identification} alt='ID' className={styles.icon} />
              </div>
              <p className='pb-3 fw-500 fs-14'>
                To verify who you are, we need your
                <br />
                identity/passport number..
              </p>
            </div>
            <Form>
              <Form.Group className='mb-5'>
                <div className='text-center pb-3'>
                  <label className={styles.label}>ID/Passport Number</label>
                </div>
                <Form.Control
                  type='text'
                  ref={IDInput}
                  value={idNumber}
                  onChange={(e) => setId(e.currentTarget.value)}
                  className={styles.idPassportNumber}
                />
                {error ? <small className={styles.error}>{error}</small> : ''}
              </Form.Group>
              <div className='text-center'>
                {showLoader ? (
                  <SpinnerAnimation />
                ) : (
                  <Button
                    className='btn btn-dark rounded-circle btn-md-rounded pulse-animate'
                    onClick={(e) => verifyId()}
                    disabled={!idNumber}
                  >
                    <span className='p-relative' style={{ right: '-2px' }}>
                      <WhiteChevronRightIcon />
                    </span>
                  </Button>
                )}
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </motion.div>
  );
}
