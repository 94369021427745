import * as Sentry from '@sentry/react';
import Identification from 'assets/images/identification.svg';
import axios from 'axios';
import { WhiteChevronRightIcon } from 'components/Icons';
import SpinnerAnimation from 'components/Spinners/SpinnerAnimation/SpinnerAnimation';
import appContext from 'context/app-context';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import SigningHeader from '../Header/Header';
import styles from './styles.module.css';

export default function ManualVerification(props) {
  const { idNumber, setCurrentPage, country, email, signatory } =
    useContext(appContext);
  const [name, setName] = useState(signatory?.first_name);
  const [surname, setSurname] = useState(signatory?.last_name);
  const [middle_name, setMiddleName] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [errors, setErrors] = useState(null);
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (name && surname) {
      if (name.trim().length !== 0 && surname.trim().length !== 0) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(true);
    }
  }, [name, surname]);

  useEffect(() => {
    if (signatory) {
      setDisabled(false);
    }
  }, [signatory]);

  const storeVerification = async () => {
    setErrors(null);
    try {
      setShowLoader(true);
      const { data } = await axios.post(`manual-verification`, {
        first_name: name ? name : signatory?.first_name,
        last_name: surname ? surname : signatory?.last_name,
        id_number: idNumber ? idNumber : signatory?.id_number,
        middle_name: middle_name,
        country: country,
        email: email,
        signatory: signatory
      });

      if (data.success) {
        setShowLoader(false);

        setCurrentPage(9);
        // if (country !== 'South Africa') {
        //   setCurrentPage(9);
        // } else {
        //   setCurrentPage(4);
        // }

        window.scrollTo(0, 0);
      }
    } catch (e) {
      if (e.response?.status === 422) {
        setErrors(e.response.data.errors);
      }
      setShowLoader(false);
      window.scrollTo(0, 0);
      console.log(e);
      Sentry.captureException(e);
    }
  };

  function previousPage() {
    setCurrentPage(3);
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Row className='m0'>
        <SigningHeader previousPage={previousPage} />
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page'
        >
          <div className={styles.main}>
            <div className='text-center pt-5'>
              <img src={Identification} alt='ID' className={styles.icon} />
            </div>
            <div className='text-center pt-4'>
              <p className='fw-700 fs-16'>{idNumber}</p>
            </div>
            <Form
              className='pt-5 p-b-80'
              onSubmit={(e) => storeVerification(e)}
            >
              <div className='text-center'>
                <Form.Group className='mb-4 ps-3 pe-3'>
                  <div className='pb-2 text-center'>
                    <label className={styles.label}>FIRST NAME</label>
                  </div>
                  <Form.Control
                    type='text'
                    className={`text-center ${styles.input}`}
                    defaultValue={name}
                    onChange={(e) => setName(e.currentTarget.value)}
                  />
                  {errors?.first_name ? (
                    <small className={styles.error}>{errors?.first_name}</small>
                  ) : (
                    ''
                  )}
                </Form.Group>
                <Form.Group className='mb-4 ps-3 pe-3'>
                  <div className='pb-2'>
                    <label className={styles.label}>MIDDLE NAME/S</label>
                  </div>
                  <Form.Control
                    type='text'
                    defaultValue={middle_name}
                    onChange={(e) => setMiddleName(e.currentTarget.value)}
                    className={`text-center ${styles.input}`}
                  />
                </Form.Group>
                <Form.Group className='mb-4 ps-3 pe-3'>
                  <div className='text-center pb-2'>
                    <label className={styles.label}>LAST NAME</label>
                  </div>
                  <Form.Control
                    type='text'
                    className={`text-center ${styles.input}`}
                    defaultValue={surname}
                    onChange={(e) => setSurname(e.currentTarget.value)}
                  />
                  {errors?.last_name ? (
                    <small className={styles.error}>{errors?.last_name}</small>
                  ) : (
                    ''
                  )}
                </Form.Group>
                <div className={styles.footer}>
                  {showLoader ? (
                    <SpinnerAnimation />
                  ) : (
                    <Button
                      className='btn btn-dark rounded-circle btn-md-rounded pulse-animate'
                      disabled={disabled}
                      onClick={(e) => storeVerification(e)}
                    >
                      <span className='p-relative' style={{ right: '-2px' }}>
                        <WhiteChevronRightIcon />
                      </span>
                    </Button>
                  )}
                </div>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </motion.div>
  );
}
