import * as Sentry from '@sentry/react';
import axios from 'axios';
import Email from 'components/Pages/Continue/Email/Email';
import CaptureUser from 'components/Pages/Signing/CaptureUser/CaptureUser';
import DocumentView from 'components/Pages/Signing/DocumentView/DocumentView';
import Finalisation from 'components/Pages/Signing/Finalisation/Finalisation';
import IDVerification from 'components/Pages/Signing/IDverification/IDVerification';
import IdentityPassportPhoto from 'components/Pages/Signing/IdentityPassportPhoto/IdentityPassportPhoto';
import MultipleFiles from 'components/Pages/Signing/MultipleFiles/MultipleFiles';
import Overview from 'components/Pages/Signing/Overview/Overview';
import SelfieVerification from 'components/Pages/Signing/SelfieVerification/SelfieVerification';
import SingleFile from 'components/Pages/Signing/SingleFile/SingleFile';
import appContext from 'context/app-context';
import { useContext, useEffect, useState } from 'react';

export const Sign = () => {
  const {
    getTransaction,
    transaction,
    getDocuments,
    documents,
    getSignatory,
    signatory,
    currentPage,
    sender
  } = useContext(appContext);
  const [page, setPage] = useState(currentPage);
  const [business, setBusiness] = useState(null);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    let transactionUuid = params.get('transaction');
    let self = params.get('self');
    let signatory = params.get('signatory') ?? '';
    getTransaction(transactionUuid);
    console.log('Sign.js Line 38 !!!');
    getDocuments(transactionUuid, self, signatory);
    getSignatory(transactionUuid, self, signatory);
    selectPage(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (sender?.has_business) {
      getBusinessInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sender]);

  const getBusinessInfo = async () => {
    try {
      const { data } = await axios.get(
        `transactions/business-info/${sender?.id}`
      );
      setBusiness(data.business);
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  };

  const signing = () => {
    switch (page) {
      case 1:
        return (
          <Overview
            signatory={signatory}
            transaction={transaction}
            documents={documents}
            sender={sender}
            business={business}
          />
        );
      case 2:
        return (
          <DocumentView
            signatory={signatory}
            transaction={transaction}
            documents={documents}
            business={business}
          />
        );
      case 3:
        return (
          <IDVerification
            signatory={signatory}
            transaction={transaction}
            documents={documents}
            business={business}
          />
        );
      case 4:
        return (
          <SelfieVerification
            signatory={signatory}
            transaction={transaction}
            documents={documents}
            business={business}
          />
        );
      case 5:
        return (
          <Finalisation
            signatory={signatory}
            transaction={transaction}
            documents={documents}
            business={business}
          />
        );
      case 6:
        return (
          <MultipleFiles
            signatory={signatory}
            transaction={transaction}
            documents={documents}
            business={business}
          />
        );
      case 7:
        return (
          <SingleFile
            signatory={signatory}
            transaction={transaction}
            documents={documents}
            business={business}
          />
        );
      case 8:
        return (
          <CaptureUser
            signatory={signatory}
            transaction={transaction}
            documents={documents}
            business={business}
          />
        );
      case 9:
        return (
          <IdentityPassportPhoto
            signatory={signatory}
            transaction={transaction}
            documents={documents}
            business={business}
          />
        );

      //Sign in / Create account flow
      case 10:
        return <Email setUserData={setUserData} userData={userData} />;

      default:
        return (
          <Overview
            signatory={signatory}
            transaction={transaction}
            documents={documents}
            sender={sender}
            business={business}
          />
        );
    }
  };

  const selectPage = (currentPage) => {
    setPage(currentPage);
    window.scrollTo(0, 0);
  };

  return (
    <div id='outer-container'>
      <div id='page-wrap'>{signing()}</div>
    </div>
  );
};
