import * as Sentry from '@sentry/react';
import axios from 'axios';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import styles from './styles.module.css';

const AdditionalFields = forwardRef((props, ref) => {
  const [fields, setFields] = useState([]);
  const [userData, setUserData] = useState(props.user);
  const [form, setForm] = useState({ fields: {} });
  const [fieldsGrouped, setFieldsGrouped] = useState([]);

  const [companyDetails, setCompanyDetails] = useState([]);

  useEffect(() => {
    const groupedObjects = groupObjectsByField(fields);
    setFieldsGrouped(groupedObjects);
  }, [fields]);

  useEffect(() => {
    userData['cell'] = '';
    userData['email'] = '';
  }, [props.user]);

  function groupObjectsByField(objects) {
    const groupedObjects = {};

    objects.forEach((obj) => {
      const groupName = obj.group_field?.replace(/_/g, ' ');
      const capitalizedGroupName =
        groupName.charAt(0).toUpperCase() + groupName.slice(1);

      if (!groupedObjects.hasOwnProperty(capitalizedGroupName)) {
        groupedObjects[capitalizedGroupName] = [];
      }

      groupedObjects[capitalizedGroupName].push(obj);
    });

    return groupedObjects;
  }

  useImperativeHandle(ref, () => ({
    async save() {
      // no fields present early exit
      if (fields.length === 0) {
        return false;
      }

      try {
        await axios.put('additional-fields/sync', form);
      } catch (e) {
        console.log(e);
      }
    }
  }));

  useEffect(() => {
    fetchFields();
    setForm({
      ...form,
      fields: {},
      document_ids: props.documents.map((document) => document.id),
      user_uuid: props.user.id
    });
  }, []);

  // Helper function to format telephone number
  const formatTelephoneNumber = (input) => {
    // Your formatting logic here, for example:
    // Assuming input is a plain number, format it as (XXX) XXX-XXXX
    return input.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  };

  useEffect(() => {
    const timerId = setTimeout(() => {
      props.validate(Object.keys(form.fields).length === fields.length);
    }, 500);

    console.log('LENGTH');
    console.log(Object.keys(form.fields).length);
    console.log(fields.length);

    return () => {
      clearTimeout(timerId);
    };
  }, [form, fields]);

  useEffect(() => {
    getUserBusinessDetails();
  }, [props.user]);

  useEffect(() => {
    let new_fields = { ...form.fields };

    fields.map((field, i) => {
      if (field?.group_field == 'company_information') {
        if (companyDetails != undefined) {
          if (
            companyDetails[field?.profile_field] !== null &&
            companyDetails[field?.profile_field] !== undefined &&
            companyDetails[field?.profile_field] !== ''
          ) {
            new_fields[field?.id] = {
              id: field?.id,
              value: companyDetails[field?.profile_field]?.trim(),
              profile_field: field?.profile_field,
              group_field: field?.group_field,
              document_ids: field?.document_ids
            };
          }
        }
      } else {
        if (
          props.user[field?.profile_field] !== null &&
          props.user[field?.profile_field] !== undefined &&
          props.user[field?.profile_field] !== ''
        ) {
          new_fields[field?.id] = {
            id: field?.id,
            value: props.user[field?.profile_field]?.trim(),
            profile_field: field?.profile_field,
            group_field: field?.group_field,
            document_ids: field?.document_ids
          };
        }
      }
    });

    setForm({
      ...form,
      fields: new_fields,
      document_ids: props.documents.map((document) => document.id),
      user_uuid: props.user.id
    });

    console.log('COMPANY DETAILS');
    console.log(companyDetails);
    return;
  }, [fields, companyDetails]);

  const setField = (field, value, profile_field, group_field) => {
    const fields = { ...form.fields };

    if (value?.trim() !== '') {
      fields[field?.id] = {
        id: field?.id,
        value: value?.trim(),
        profile_field: profile_field,
        group_field: group_field,
        document_ids: field?.document_ids
      };
    } else if (Object.keys(fields).length > 0) {
      delete fields[field?.id];
    }

    setForm({
      ...form,
      fields: fields,
      document_ids: props.documents.map((document) => document.id),
      user_uuid: props.user.id
    });
  };

  async function fetchFields() {
    try {
      const { data } = await axios.get(
        `transactions/${props.transaction.id}/signatory/${props.user.id}/fields`
      );
      setFields(data);
    } catch (e) {
      Sentry.captureException(e);
    }
  }

  const getUserBusinessDetails = async () => {
    try {
      const { data } = await axios.get(`/businesses/user/businesses`, {
        user: props.user
      });
      if (data.success) {
        setCompanyDetails(data.businesses[0]);
        console.log(data.businesses[0]);
      }
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  };

  return (
    <>
      {fields.length > 0 && (
        <>
          <p>
            Please provide the following
            <br />
            additional information
          </p>
          <Row className='m0'>
            <Col
              lg={{ span: 8, offset: 2 }}
              md={{ span: 12, offset: 0 }}
              className=''
            >
              {Object.entries(fieldsGrouped).map(([group, objects]) => (
                <div key={group} className='text-left'>
                  <h5 className='group-heading'>{group}</h5>

                  {objects?.map((field, i) => (
                    <Form.Group key={field?.id} className='mb-4'>
                      <div className='pb-2'>
                        <label className={styles.labelLeft}>
                          {field?.name}
                        </label>
                      </div>
                      <Form.Control
                        type='text'
                        className={styles.input}
                        defaultValue={
                          companyDetails !== undefined &&
                          field?.group_field === 'company_information'
                            ? companyDetails[field?.profile_field]
                            : userData[field?.profile_field]
                        }
                        onChange={(e) => {
                          let value = e.target.value;
                          if (field?.name === 'Tel Number') {
                            const numericValue = value.replace(/\D/g, '');
                            console.log('Original Value:', value);
                            console.log('Numeric Value:', numericValue);
                            value = formatTelephoneNumber(numericValue);
                            console.log('Formatted Value:', value);
                            e.target.value = value;
                          }
                          console.log(field?.name, 'field name');
                          console.log(value, 'formatted value');
                          setField(
                            field,
                            value,
                            field?.profile_field,
                            field?.group_field
                          );
                        }}
                      />
                    </Form.Group>
                  ))}
                </div>
              ))}
            </Col>
          </Row>
        </>
      )}
    </>
  );
});
export default AdditionalFields;
