import AddPassword from 'components/Pages/Continue/AddPassword/AddPassword';
import Email from 'components/Pages/Continue/Email/Email';
import EmailConfirmation from 'components/Pages/Continue/Email/EmailConfirmation';
import IndividualOrBusiness from 'components/Pages/Continue/Email/IndividualOrBusiness';
import TermsAndPrivacyConfirmation from 'components/Pages/Continue/Email/TermsAndPrivacyConfirmation';
import LastPage from 'components/Pages/Continue/LastPage/LastPage';
import OTP from 'components/Pages/Continue/OTP/OTP';
import Phone from 'components/Pages/Continue/Phone/Phone';
import SignOTP from 'components/Pages/Continue/SignOTP/SignOTP';
import AddBusiness from 'components/Pages/Profile/AddBusiness/AddBusiness';
import appContext from 'context/app-context';
import { useContext, useEffect, useState } from 'react';

export const Continue = () => {
  const { continuePage, getTransaction, getDocuments, getSignatory } =
    useContext(appContext);
  const [page, setPage] = useState(continuePage);
  const [userData, setUserData] = useState(null);
  useEffect(() => {
    let url = new URL(window.location.href);
    let params = new URLSearchParams(url.search);
    let transactionUuid = params.get('transaction');
    if (transactionUuid) {
      let self = params.get('self');
      let signatory = params.get('signatory') ?? '';
      getTransaction(transactionUuid);
      console.log('Continue.js line 26');
      getDocuments(transactionUuid, self, signatory);
      getSignatory(transactionUuid, self, signatory);
    }
    selectPage(continuePage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [continuePage]);

  const continueScreens = () => {
    switch (page) {
      case 0:
        return <Email setUserData={setUserData} userData={userData} />;
      case 1.1:
        return <EmailConfirmation />;
      case 1.2:
        return <TermsAndPrivacyConfirmation />;
      case 1:
        return <OTP />;
      case 2:
        return <AddPassword userData={userData} />;
      case 2.1:
        return <IndividualOrBusiness />;
      case 3:
        return <LastPage />;
      case 4:
        return <SignOTP />;
      case 5:
        return <Phone />;
      case 6:
        return <AddBusiness />;
      default:
        return <Email />;
    }
  };

  const selectPage = (continuePage) => {
    setPage(continuePage);
    window.scrollTo(0, 0);
  };

  return (
    <div id='outer-container'>
      <div id='page-wrap'>{continueScreens()}</div>
    </div>
  );
};
