import * as Sentry from '@sentry/react';
import ChevronLeftIcon from 'assets/images/chevron-left.svg';
import Cross from 'assets/images/cross.svg';
import home from 'assets/images/home.svg';
import axios from 'axios';
import SpinnerAnimation from 'components/Spinners/SpinnerAnimation/SpinnerAnimation';
import SpinnerSmall from 'components/Spinners/SpinnerSmall/SpinnerSmall';
import appContext from 'context/app-context';
import { motion } from 'framer-motion';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import ScrollBox from 'react-responsive-scrollbox';
import { useNavigate } from 'react-router-dom';
import ViewTransactionDocument from '../../Pages/Transactions/ViewTransactionDocument/ViewTransactionDocument';
import styles from '../styles.module.css';

export default function SendAcknowledgements(props) {
  const navigate = useNavigate();

  const {
    user,
    setSendingPage,
    setCurrentDocument,
    currentTransaction,
    getTransaction
  } = useContext(appContext);
  const [isCreateAcknowledgements, setIsCreateAcknowledgements] =
    useState(false);

  const [showList, setShowList] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [isNew, setIsNew] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [openViewDocument, setOpenViewDocument] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [documentAcknoledgements, setDocumentAcknoledgements] = useState([]);
  const [documentIndex, setDocumentIndex] = useState();
  const [acknowledgementIndex, setAcknowledgementIndex] = useState();
  const [acknowledgementsCount, setAcknowledgementsCount] = useState([]);
  const [selectedAcknowledgement, setSelectedAcknowledgement] = useState();
  const [thisDoc, setThisDoc] = useState([]);

  useEffect(() => {
    setDocuments(currentTransaction?.documents);
    countAcknowledgements(currentTransaction?.documents);

    setShowLoader(false);
  }, [currentTransaction]);

  useEffect(() => {
    listAcknowledgements(documentIndex);
    countAcknowledgements(documents);
  }, [openViewDocument]);

  function goToNext() {
    sessionStorage.removeItem('sender_added');
    setSendingPage(4);
  }

  function previousPage(page) {
    if (page == 'view-documents') {
      setShowList(false);
    }

    if (page == 'goto-step2') {
      setSendingPage(8);
    }
  }

  function listAcknowledgements(index) {
    if (documents?.length > 0) {
      setCurrentDocument(documents[index]);
      setThisDoc(documents[index]);
      setShowList(true);

      getAcknowledgements(
        documents[index]?.uuid ? documents[index].uuid : documents[index].id
      );
    }
  }

  // Count how many people accepted and total
  function countSignatories(array, type) {
    if (type == 'signed') {
      let count = 0;
      array.forEach((element) => {
        if (element.has_accepted == 1) {
          count++;
        }
      });
      return count;
    }

    if (type == 'total') {
      return array.length;
    }
  }

  // HTTP get all acknowledgements
  async function getAcknowledgements(document_id) {
    setShowLoader(true);
    try {
      const { data } = await axios.get(
        `/acknowledgement/signatories/${document_id}`
      );
      setDocumentAcknoledgements(data);
      setShowLoader(false);
    } catch (e) {
      Sentry.captureException(e);
      setShowLoader(false);
    }
  }

  // HTTP get and count acknowledgements
  async function getCountAcknowledgements(document_id) {
    try {
      const { data } = await axios.get(
        `/acknowledgement/${document_id}?count=true`,
        {
          count: true
        }
      );

      if (data > 0) {
        setIsCreateAcknowledgements(true);
      }

      setAcknowledgementsCount((current) => [
        ...current,
        { uuid: document_id, count: data }
      ]);
    } catch (e) {
      Sentry.captureException(e);
      setShowLoader(false);
    }
  }

  function countAcknowledgements(docs) {
    setAcknowledgementsCount([]);

    for (let index = 0; index < docs.length; index++) {
      const item = docs[index];
      getCountAcknowledgements(item.uuid);
    }
  }

  return (
    <>
      {!openViewDocument && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.3 }}
          id='add-acknowledgements'
        >
          {/* HEADER NAV */}
          <Row className='m0 '>
            <Col xs={{ span: 12 }} className='p0'>
              <div className={styles.header_top}>
                <div
                  className={styles.chevronLeftIcon}
                  onClick={() =>
                    showList
                      ? previousPage('view-documents')
                      : previousPage('goto-step2')
                  }
                >
                  <img src={ChevronLeftIcon} alt='go back' />
                </div>

                <p className='mb-1'>Step 4 of 4</p>
                <p className='m0 fw-700 fs-16'>Special acknowledgements</p>
                <img
                  src={home}
                  alt='home'
                  className={styles.homeBtn}
                  onClick={() => navigate('/')}
                />
              </div>
            </Col>
          </Row>

          {/* LIST DOCUMENTS TO ADD */}

          {!showList && (
            <Row className='m0'>
              <Col
                lg={{ span: 4, offset: 4 }}
                md={{ span: 6, offset: 3 }}
                className='bg-white p-t-100 min-height-full-page'
              >
                <Form.Group className='mb-4 ps-3 pe-3 fade-in'>
                  <div className='pb-2 text-center px-5 mb-2 max-width mt-2'>
                    {/* <label className={styles.label}>SAVE AS FREQUENT SIGNER</label> */}
                    <p className=''>
                      Would you like to add special acknowledgements to any of
                      the files for signers to accept when signing?
                    </p>
                  </div>

                  <label className='react-switch ml-0 w-100'>
                    <input
                      onChange={(e) =>
                        setIsCreateAcknowledgements(e.target.checked)
                      }
                      className='react-switch-checkbox'
                      value={isCreateAcknowledgements}
                      type='checkbox'
                    />
                    <div
                      style={
                        isCreateAcknowledgements
                          ? {
                              left: 'calc(100% - 4px)',
                              transform: 'translateX(-100%)'
                            }
                          : {}
                      }
                      className='react-switch-button'
                    />
                    <div className='react-switch-labels'>
                      <span>No</span>
                      <span>Yes</span>
                    </div>
                  </label>
                </Form.Group>

                {isCreateAcknowledgements && (
                  <>
                    {showLoader ? (
                      <div className='p-b-50'>
                        <SpinnerAnimation />
                      </div>
                    ) : (
                      <div className='acknowledgement-list'>
                        <div className='p-b-200'>
                          {documents.map((document, index) => (
                            <div className='p-l-15 p-r-15' key={index}>
                              <div
                                onClick={() => (
                                  listAcknowledgements(index),
                                  setDocumentIndex(index)
                                )}
                                className='mb-2 cardWhite h-70 pointer card fade-in'
                              >
                                <div className='styles_cardBody__s-IRm card-body document-details-card'>
                                  <span className='badge badge-info fw-600'>
                                    PDF
                                  </span>

                                  <div className='styles_cardBodyText__DhJ4d card-text'>
                                    <div className='one-line-ellipsis'>
                                      <p className='m0 mb-0 fs-14 fw-700'>
                                        {document.title}
                                      </p>
                                    </div>
                                    <span className='color-lightgray fs-12 fw-700'>
                                      {acknowledgementsCount.map(
                                        (count, index) => (
                                          <span className='fade-in'>
                                            {count.count === 0 ? null : (
                                              <>
                                                {document.uuid ===
                                                  count.uuid && (
                                                  <>
                                                    {count.count}&nbsp;
                                                    acknowledgement
                                                    {count.count === 1
                                                      ? ''
                                                      : 's'}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </span>
                                        )
                                      )}
                                    </span>
                                  </div>
                                  <div className='styles_viewFileBtn__XjyRN'>
                                    <img
                                      src='/static/media/chevron-right.00ff529e1b01f37594da63a561b89dc1.svg'
                                      alt='Chevron Right'
                                      className='icon'
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </Col>
            </Row>
          )}

          {/* LIST ACKNOWLEDGEMENTS*/}
          {showList && (
            <Row className='m0'>
              <Col
                lg={{ span: 4, offset: 4 }}
                md={{ span: 6, offset: 3 }}
                className='p-t-100 min-height-full-page bg-white'
              >
                <div className='header-div text-center fade-in'>
                  <span className='badge badge-info mb-2 fw-600'>PDF</span>
                  <h6 className='text-center fw-700'>{thisDoc?.title}</h6>
                  <p className='mt-2 mb-4 text-center px-5'>
                    Add special acknowledgements, such as references,
                    confirmations, amendments or clauses to this file.
                  </p>
                </div>

                {showLoader ? (
                  <div className='p-b-50'>
                    <SpinnerAnimation />
                  </div>
                ) : (
                  <div className='acknowledgement-list'>
                    <div className='p-b-200'>
                      {documentAcknoledgements?.map((item, index) => (
                        <div className='p-l-15 p-r-15' key={index}>
                          <div
                            onClick={() => (
                              setOpenViewDocument(true),
                              setIsEdit(true),
                              setIsNew(false),
                              setAcknowledgementIndex(index),
                              setSelectedAcknowledgement(item)
                            )}
                            className='mb-2 cardWhite h-70 pointer card fade-in'
                          >
                            <div className='styles_cardBody__s-IRm card-body'>
                              <div className='styles_cardBodyText__DhJ4d card-text'>
                                <div className='one-line-ellipsis'>
                                  <p className='m0 mb-0 fs-14 fw-700'>
                                    <span className='fs-14 fw-700 count'>
                                      {index + 1}
                                      {'. '}
                                    </span>
                                    {item.support_text.substring(0, 32) + '...'}
                                  </p>
                                </div>
                                <span className='color-lightgray fs-12 fw-700'>
                                  {' '}
                                  {countSignatories(item.signatories, 'signed')}
                                  /{countSignatories(item.signatories, 'total')}{' '}
                                  accepted
                                </span>
                              </div>
                              <div className='styles_viewFileBtn__XjyRN'>
                                <img
                                  src='/static/media/chevron-right.00ff529e1b01f37594da63a561b89dc1.svg'
                                  alt='Chevron Right'
                                  className='icon'
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
          )}

          {/* ADD ACKNOWLEDGEMENTS */}
          {showList && (
            <Row className='m0'>
              <Col
                lg={{ span: 4, offset: 4 }}
                md={{ span: 6, offset: 3 }}
                className=' p-t-100'
              >
                <div className={styles.footerAnimate}>
                  <Button
                    variant={
                      documentAcknoledgements.length > 0
                        ? 'outline-dark'
                        : 'dark'
                    }
                    className={
                      documentAcknoledgements.length > 0
                        ? 'btn-rounded btn-block mb-3 fw-700'
                        : 'btn-block btn-rounded mb-2 fw-700'
                    }
                    onClick={() => (
                      setOpenViewDocument(true),
                      setIsEdit(false),
                      setIsNew(true)
                    )}
                  >
                    Add acknowledgement
                  </Button>

                  {documentAcknoledgements.length > 0 && (
                    <Button
                      variant='dark'
                      className='btn-block btn-rounded fw-700'
                      onClick={(e) => setShowList(false)}
                    >
                      Continue
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          )}

          {/* CONTINUE TO NEXT STEP */}
          {!showList && (
            <Row className='m0'>
              <Col
                lg={{ span: 4, offset: 4 }}
                md={{ span: 6, offset: 3 }}
                className=''
              >
                <div className={styles.footerAnimate}>
                  <Button
                    variant='dark'
                    className='btn-block btn-rounded mb-4 fw-700'
                    onClick={(e) => goToNext(e)}
                  >
                    Continue
                  </Button>

                  <span
                    className='m0 fw-700 fs-16 pointer'
                    onClick={(e) => setSendingPage(8)}
                  >
                    Back
                  </span>
                </div>
              </Col>
            </Row>
          )}
        </motion.div>
      )}

      {/* VIEW DOCUMENT AND ADD / EDIT ACKNOWLEGEMENTS */}
      {openViewDocument && (
        <ViewTransactionDocument
          isNew={isNew}
          isEdit={isEdit}
          setOpenViewDocument={setOpenViewDocument}
          documentIndex={documentIndex}
          acknowledgementIndex={acknowledgementIndex}
          selectedAcknowledgement={selectedAcknowledgement}
        />
      )}
    </>
  );
}
