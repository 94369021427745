import SpinnerAnimation from 'components/Spinners/SpinnerAnimation/SpinnerAnimation';
import appContext from 'context/app-context';
import React, { useContext, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import styles from './styles.module.css';

export default function TermsAndPrivacyConfirmation({
  setContinueFromConfirmation
}) {
  const { setContinuePage } = useContext(appContext);
  const [showLoader, setShowLoader] = useState(false);
  const confirm = () => {
    setContinuePage(2);
  };
  const decline = () => {
    setContinueFromConfirmation(false);
  };
  const welcomeMessages = () => {
    return (
      <>
        <div className='text-center'>
          <p className='mb-2 fw-700 fs-16'>
            By accessing or using Dewly's
            <br />
            services, I accept & agree to the
          </p>
          <p className='fs-16 color-lightgray fw-600 mt-3'>
            <a
              href='https://dewly.net/terms-and-conditions/'
              target='_blank'
              rel='noreferrer'
            >
              Terms of service
            </a>{' '}
            <span className='color-lightgray'> &</span>{' '}
            <a
              href='https://dewly.net/privacy-policy/'
              target='_blank'
              rel='noreferrer'
            >
              Privacy Policy
            </a>
          </p>
        </div>
      </>
    );
  };

  return (
    <>
      <div className={`${styles.footerWhite}`}>
        <Row className='m0 pt-4'>
          <Col
            lg={{ span: 4, offset: 4 }}
            md={{ span: 8, offset: 2 }}
            xs={{ span: 12, offset: 0 }}
            className={`${styles.footerInner}`}
          >
            <div className='text-center m-t-20 m-b-40'>{welcomeMessages()}</div>
            <div>
              <div className='text-center ps-3 pe-3'>
                {showLoader ? (
                  <SpinnerAnimation />
                ) : (
                  <>
                    <Button
                      variant='dark'
                      className='btn-rounded btn-block'
                      onClick={confirm}
                    >
                      <span className='fs-16 fw-700'>Accept</span>
                    </Button>
                  </>
                )}
              </div>
              <div className='text-center ps-3 pe-3 mt-2'>
                <Button
                  variant='outline-dark'
                  className='btn-rounded btn-block mt-2 fade-in'
                  onClick={decline}
                >
                  <span className='pointer fs-16 fw-700'>Decline</span>
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
