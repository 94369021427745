import signedScribble from 'assets/images/black-scribble.svg';
import { ChevronRightIcon } from 'components/Icons/Icons';
import appContext from 'context/app-context';
import React, { useContext, useEffect, useState } from 'react';
import { Card } from 'react-bootstrap';
import { createSearchParams, useNavigate } from 'react-router-dom';
import styles from './styles.module.css';

export const Document = (props) => {
  const {
    setCurrentPage,
    setCurrentDocument,
    documents,
    unSignedDocuments,
    getTransaction,
    getDocuments,
    getSignatory,
    currentPage
  } = useContext(appContext);
  const navigate = useNavigate();
  const [page, setPage] = useState(currentPage);
  const [userType, setUserType] = useState('new');

  let url = new URL(window.location.href);
  let params = new URLSearchParams(url.search);
  let signatory = params.get('signatory') ?? '';
  let signType = params.get('sign_type');

  const updatePage = () => {
    console.log(sessionStorage.getItem('token'));
    if (!sessionStorage.getItem('token')) {
      let url = new URL(window.location.href);
      let params = new URLSearchParams(url.search);
      let transactionUuid = params.get('transaction');
      let signatory = params.get('signatory') ?? '';
      let self = params.get('self');
      let signType = params.get('sign_type');
      setUserType(signType);
      getTransaction(transactionUuid);
      getDocuments(transactionUuid, self, signatory);
      getSignatory(transactionUuid, self, signatory);

      navigate({
        pathname: '/continue',
        search: `?${createSearchParams(
          'transaction=' +
            transactionUuid +
            '&sign_type=' +
            signType +
            '&signatory=' +
            signatory +
            '&self=' +
            self
        )}`
      });
    } else {
      setCurrentPage(2);
      let token = sessionStorage.getItem('token');
      let otpConfimed = sessionStorage.getItem('otp_confirmed');
      if (
        unSignedDocuments === documents.length &&
        token &&
        !otpConfimed &&
        props.signType !== 'new'
      ) {
        //Remove sign otp
        // let url = new URL(window.location.href)
        // let newPath = url.pathname + url.search;
        // window.location.href = newPath.replace('sign', 'continue');
      }
      setCurrentDocument(props.document);
    }
  };

  console.log(props.document);

  return (
    <>
      <Card className='mb-2 cardWhite pointer' onClick={(e) => updatePage()}>
        <Card.Body className={styles.cardBody}>
          <span className='badge badge-info fw-600'>PDF</span>
          <Card.Text className={styles.cardBodyText} as='h6'>
            <div>
              <p className='m0 fw-700 fs-14'>{props.title}</p>
            </div>
            {/* <span className='color-lightgray fs-12 fw-600'>
              {props.document?.signed_at ? (
                <>
                  <img
                    src={signedScribble}
                    alt='signed'
                    className={styles.signedScribble}
                  />{' '}
                  Signed
                </>
              ) : (
                'Unsigned'
              )}
            </span> */}
          </Card.Text>
          <div className={styles.viewFileBtn}>
            <ChevronRightIcon />
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
