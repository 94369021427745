import styles from './styles.module.css';
import { Card, Col, Row } from 'react-bootstrap';
import SpinnerAnimation from 'components/Spinners/SpinnerAnimation/SpinnerAnimation';
import { BillingDetails } from '../BillingDetails';

export const BillingCard = ({
  billing,
  date,
  verifications,
  credits,
  showLoader
}) => {
  return (
    <div className='pe-3 ps-3'>
      <Card className='mb-2 cardGray'>
        <Card.Body className={styles.cardBody}>
          <Card.Text className={styles.cardBodyText} as='h6'>
            <p className='m0 fs-18 fw-800'>This month</p>
            {showLoader ? (
              <>
                <SpinnerAnimation />
              </>
            ) : (
              <>
                <BillingDetails
                  billing={billing}
                  date={date}
                  verifications={verifications}
                  credits={credits}
                  showLoader={showLoader}
                />
              </>
            )}
          </Card.Text>
        </Card.Body>
      </Card>
    </div>
  );
};
