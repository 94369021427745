import ScrollBox from 'react-responsive-scrollbox';
import DocumentItem from './DocumentItem';
import styles from './styles.module.css';

export default function DocumentList({
  documents,
  onItemClick,
  saved,
  itemActive,
  consent
}) {
  function handleItemClick(document) {
    onItemClick(document);
  }

  return (
    <>
      <div
        className={!consent || (saved && !itemActive) ? 'p-b-180' : 'p-b-100'}
      >
        <div className='document-list'>
          {documents.map((document) => (
            <DocumentItem
              onClick={() => handleItemClick(document)}
              key={document.id}
              count={document.additional_field_count}
              document={document}
            />
          ))}
        </div>
      </div>
    </>
  );
}
