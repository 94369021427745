import * as Sentry from '@sentry/react';
import uploadIcon from 'assets/images/upload.svg';
import axios from 'axios';
import GoBackHeader from 'components/GoBackHeader/GoBackHeader';
import SpinnerAnimation from 'components/Spinners/SpinnerAnimation/SpinnerAnimation';
import appContext from 'context/app-context';
import { motion } from 'framer-motion';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { useFileUpload } from 'use-file-upload';
import styles from './styles.module.css';

const industries = [
  'Accounting',
  'Airlines/Aviation',
  'Alternative Dispute Resolution',
  'Alternative Medicine',
  'Animation',
  'Apparel/Fashion',
  'Architecture/Planning',
  'Arts/Crafts',
  'Automotive',
  'Aviation/Aerospace',
  'Banking/Mortgage',
  'Biotechnology/Greentech',
  'Broadcast Media',
  'Building Materials',
  'Business Supplies/Equipment',
  'Capital Markets/Hedge Fund/Private Equity',
  'Chemicals',
  'Civic/Social Organization',
  'Civil Engineering',
  'Commercial Real Estate',
  'Computer Games',
  'Computer Hardware',
  'Computer Networking',
  'Computer Software/Engineering',
  'Computer/Network Security',
  'Construction',
  'Consumer Electronics',
  'Consumer Goods',
  'Consumer Services',
  'Cosmetics',
  'Dairy',
  'Defense/Space',
  'Design',
  'E-Learning',
  'Education Management',
  'Electrical/Electronic Manufacturing',
  'Entertainment/Movie Production',
  'Environmental Services',
  'Events Services',
  'Executive Office',
  'Facilities Services',
  'Farming',
  'Financial Services',
  'Fine Art',
  'Fishery',
  'Food Production',
  'Food/Beverages',
  'Fundraising',
  'Furniture',
  'Gambling/Casinos',
  'Glass/Ceramics/Concrete',
  'Government Administration',
  'Government Relations',
  'Graphic Design/Web Design',
  'Health/Fitness',
  'Higher Education/Acadamia',
  'Hospital/Health Care',
  'Hospitality',
  'Human Resources/HR',
  'Import/Export',
  'Individual/Family Services',
  'Industrial Automation',
  'Information Services',
  'Information Technology/IT',
  'Insurance',
  'International Affairs',
  'International Trade/Development',
  'Internet',
  'Investment Banking/Venture',
  'Investment Management/Hedge Fund/Private Equity',
  'Judiciary',
  'Law Enforcement',
  'Law Practice/Law Firms',
  'Legal Services',
  'Legislative Office',
  'Leisure/Travel',
  'Library',
  'Logistics/Procurement',
  'Luxury Goods/Jewelry',
  'Machinery',
  'Management Consulting',
  'Maritime',
  'Market Research',
  'Marketing/Advertising/Sales',
  'Mechanical or Industrial Engineering',
  'Media Production',
  'Medical Equipment',
  'Medical Practice',
  'Mental Health Care',
  'Military Industry',
  'Mining/Metals',
  'Motion Pictures/Film',
  'Museums/Institutions',
  'Music',
  'Nanotechnology',
  'Newspapers/Journalism',
  'Non-Profit/Volunteering',
  'Oil/Energy/Solar/Greentech',
  'Online Publishing',
  'Other Industry',
  'Outsourcing/Offshoring',
  'Package/Freight Delivery',
  'Packaging/Containers',
  'Paper/Forest Products',
  'Performing Arts',
  'Pharmaceuticals',
  'Philanthropy',
  'Photography',
  'Plastics',
  'Political Organization',
  'Primary/Secondary Education',
  'Printing',
  'Professional Training',
  'Program Development',
  'Public Relations/PR',
  'Public Safety',
  'Publishing Industry',
  'Railroad Manufacture',
  'Ranching',
  'Real Estate/Mortgage',
  'Recreational Facilities/Services',
  'Religious Institutions',
  'Renewables/Environment',
  'Research Industry',
  'Restaurants',
  'Retail Industry',
  'Security/Investigations',
  'Semiconductors',
  'Shipbuilding',
  'Sporting Goods',
  'Sports',
  'Staffing/Recruiting',
  'Supermarkets',
  'Telecommunications',
  'Textiles',
  'Think Tanks',
  'Tobacco',
  'Translation/Localization',
  'Transportation',
  'Utilities',
  'Venture Capital/VC',
  'Veterinary',
  'Warehousing',
  'Wholesale',
  'Wine/Spirits',
  'Wireless',
  'Writing/Editing'
];

export default function AddBusiness() {
  const { setProfilePage, user, countries, setContinuePage } =
    useContext(appContext);
  const [business, setBusiness] = useState(null);
  const [name, setName] = useState(business?.name);
  const [registration, setRegistration] = useState(business?.registration);
  const [email, setEmail] = useState(business?.email);
  const [streetName, setStreetName] = useState(business?.street_name);
  const [country, setCountry] = useState(business?.country);
  const [cell, setCell] = useState(business?.cell);
  const [website, setWebsite] = useState(business?.website);
  const [building, setBuilding] = useState(business?.building);
  const [suburb, setSuburb] = useState(business?.suburb);
  const [city, setCity] = useState(business?.city);
  const [industry, setIndustry] = useState(business?.industry);
  const [businessId, setBusinessId] = useState('');
  const [companyLogo, selectCompanyLogo] = useFileUpload();
  const [logo, setLogo] = useState(null);
  const [showLogoLoader, setShowLogoLoader] = useState(false);
  const [loader, setLoader] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [errors, setErrors] = useState(null);

  let navigate = useNavigate();
  const previousPage = () => {
    if (window.location.href.includes('continue')) {
      setContinuePage(2);
    } else {
      setProfilePage(0);
    }
  };

  useEffect(() => {
    getBusiness();
    getCompanyLogo();
  }, [user]);

  useEffect(() => {
    saveCompanyLogo();
  }, [companyLogo]);

  useEffect(() => {
    setTimeout(() => {
      setLoader(true);
    }, 1500);
  });

  const getBusiness = async () => {
    try {
      const { data } = await axios.get(`users/${user?.id}/business`);
      setBusiness(data.business);
      setName(data.business?.name);
      if (!data.business?.email) {
        setEmail(user?.email);
      } else {
        setEmail(data.business.email);
      }
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
    }
  };

  const getCompanyLogo = async () => {
    try {
      const { data } = await axios.get(
        `users/${user.id}/documents?type=company_logo`
      );
      setLogo(data.company_logo);
      setLoader(true);
    } catch (e) {
      console.log(e);
      Sentry.captureException(e);
      setLoader(true);
    }
  };

  function emailValidation(email) {
    setErrors(null);
    setEmail(email);
    let oldEmail = business?.email ? business?.email : user?.email;
    if (email === '') {
      setEmailError('Please enter your business email address');
    } else if (!isValiEmail(email)) {
      setEmailError('Please enter a valid email address');
    } else if (email !== oldEmail) {
      setEmailError(
        'Please note: Changing this email address will also change the account email that is used for login'
      );
    } else {
      setEmailError(false);
    }
  }

  const createBusiness = async () => {
    setErrors(null);
    if (!isValiEmail(email)) {
      return;
    }
    try {
      let { data } = await axios.post(`/businesses`, {
        name: name ? name : business?.name,
        has_business: 1,
        street_name: streetName ? streetName : business?.street_name,
        cell: cell ? cell : business?.cell,
        registration: registration ? registration : business?.registration,
        email: email ? email : business?.email,
        country: country ? country : business?.country,
        website: website ? website : business?.website,
        building: building ? building : business?.building,
        suburb: suburb ? suburb : business?.suburb,
        city: city ? city : business?.city,
        industry: industry ? industry : business?.industry
      });

      if (data.success) {
        setBusiness(data.business);
        sessionStorage.setItem('businessId', data.business.id);
        window.location.href = '/my-profile';
        // navigate('/');
      }
    } catch (e) {
      if (e.response.status === 422) {
        let errors = e.response.data.errors;
        setErrors(errors);
      }
      console.log(e);
      Sentry.captureException(e);
    }
  };
  const switchPersonal = () => {
    setContinuePage(5);
  };
  const uploadRef = useRef();
  const handleUpload = () => {
    uploadRef.current.click();
  };

  function getBase64(file, cb) {
    if (file) {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        cb(reader.result);
      };
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  }

  const saveCompanyLogo = async (file) => {
    getBase64(file, (result) => {
      setLogo(result);
    });

    if (file) {
      let formData = new FormData();
      formData.append('type', 'company_logo');
      formData.append('document', file);

      const config = {
        headers: {
          'content-type': `multipart/form-data;`
        }
      };

      try {
        setShowLogoLoader(true);
        await axios.post(`users/${user.id}/documents`, formData, {
          ...config
        });
        setShowLogoLoader(false);
      } catch (e) {
        console.log(e);
        setShowLogoLoader(false);
        Sentry.captureException(e);
      }
    }
  };

  function isValiEmail(val) {
    let regEmail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!regEmail.test(val)) {
      return false;
    }
    return true;
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white'
        >
          <GoBackHeader
            previousPage={previousPage}
            pageName={'Business profile'}
          />
          {!loader ? (
            <>
              <Row>
                <Col lg={{ span: 4, offset: 4 }} md={{ span: 6, offset: 3 }}>
                  <div className={styles.fullscreenSpinner}>
                    <div className='center-content'>
                      <SpinnerAnimation />
                    </div>
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <div className='p-t-100'>
                <Form className='text-left'>
                  <Form.Group className='mb-4'>
                    <div className='pb-2 ps-4'>
                      <label className={styles.label}>Add Company Name</label>
                    </div>
                    <Form.Control
                      type='text'
                      defaultValue={business?.name}
                      onChange={(e) => setName(e.currentTarget.value)}
                      className={styles.input}
                    />
                    {(errors && errors.name) || !name ? (
                      <small className={styles.emailError}>
                        Please enter your business name
                      </small>
                    ) : (
                      ''
                    )}
                  </Form.Group>
                  <div className='text-center'>
                    <p className={`ps-4 pe-4 ${styles.logoLabel}`}>
                      UPLOAD YOUR COMPANY LOGO
                    </p>
                    <>
                      {companyLogo ? (
                        <>
                          <img
                            src={companyLogo.source}
                            alt='id'
                            className={styles.idPhoto}
                          />
                        </>
                      ) : logo ? (
                        <>
                          <img src={logo} alt='id' className={styles.idPhoto} />
                        </>
                      ) : (
                        <>
                          <div className={styles.IDPlaceholder}></div>
                        </>
                      )}
                      <div>
                        {showLogoLoader ? (
                          <div className='mb-3'>
                            <SpinnerAnimation />
                          </div>
                        ) : (
                          <Button
                            variant='dark'
                            className='btn-rounded btn-50 p-relative top-less-20 p0'
                            onClick={handleUpload}
                          >
                            <img
                              src={uploadIcon}
                              alt='edit'
                              className={styles.edit}
                            />

                            <input
                              type='file'
                              ref={uploadRef}
                              style={{ display: 'none' }}
                              accept='image/*'
                              onChange={(e) =>
                                saveCompanyLogo(e.target.files[0])
                              }
                            />
                          </Button>
                        )}
                      </div>
                    </>
                  </div>
                  <Form.Group className='mb-4'>
                    <div className='pb-2 ps-4'>
                      <label className={styles.label}>Contact Number</label>
                    </div>
                    <Form.Control
                      type='number'
                      inputMode='numeric'
                      defaultValue={business?.cell}
                      onChange={(e) => setCell(e.currentTarget.value)}
                      className={styles.input}
                    />
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <div className='pb-2 ps-4'>
                      <label className={styles.label}>Website</label>
                    </div>
                    <Form.Control
                      type='text'
                      defaultValue={business?.website}
                      onChange={(e) => setWebsite(e.currentTarget.value)}
                      className={styles.input}
                    />
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <div className='pb-2 ps-4'>
                      <label className={styles.label}>Email</label>
                    </div>
                    <Form.Control
                      type='email'
                      defaultValue={email}
                      onChange={(e) => emailValidation(e.currentTarget.value)}
                      className={styles.input}
                    />

                    {errors && errors.email ? (
                      <small className={styles.emailError}>
                        Email already in use. Please use a different email
                      </small>
                    ) : (
                      ''
                    )}

                    {emailError && !errors ? (
                      <>
                        <div className={styles.emailError}>{emailError}</div>
                      </>
                    ) : null}
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <div className='pb-2 ps-4'>
                      <label className={styles.label}>Street Name</label>
                    </div>
                    <Form.Control
                      type='text'
                      defaultValue={business?.street_name}
                      onChange={(e) => setStreetName(e.currentTarget.value)}
                      className={styles.input}
                    />
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <div className='pb-2 ps-4'>
                      <label className={styles.label}>
                        Building Name / Number
                      </label>
                    </div>
                    <Form.Control
                      type='text'
                      defaultValue={business?.building}
                      onChange={(e) => setBuilding(e.currentTarget.value)}
                      className={styles.input}
                    />
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <div className='pb-2 ps-4'>
                      <label className={styles.label}>Suburb</label>
                    </div>
                    <Form.Control
                      type='text'
                      defaultValue={business?.suburb}
                      onChange={(e) => setSuburb(e.currentTarget.value)}
                      className={styles.input}
                    />
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <div className='pb-2 ps-4'>
                      <label className={styles.label}>City</label>
                    </div>
                    <Form.Control
                      type='text'
                      defaultValue={business?.city}
                      onChange={(e) => setCity(e.currentTarget.value)}
                      className={styles.input}
                    />
                  </Form.Group>
                  <Form.Group className='mb-4'>
                    <div className='pb-2 ps-4'>
                      <label className={styles.label}>Country</label>
                    </div>
                    <Form.Select
                      defaultValue={
                        business?.country ? business?.country : 'South Africa'
                      }
                      onChange={(e) => setCountry(e.currentTarget.value)}
                    >
                      <option disabled value='South Africa'>
                        South Africa
                      </option>
                      {countries.map((country, key) => {
                        return (
                          <option key={key} value={country}>
                            {country}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </Form.Group>

                  <Form.Group className='mb-4'>
                    <div className='pb-2 ps-4'>
                      <label className={styles.label}>Industry</label>
                    </div>
                    <Form.Select
                      defaultValue={
                        business?.industry ? business?.industry : null
                      }
                      onChange={(e) => setIndustry(e.currentTarget.value)}
                    >
                      <option disabled value={'select'} hidden>
                        Select
                      </option>
                      {industries.map((industry, key) => {
                        return (
                          <option key={key} value={industry}>
                            {industry}
                          </option>
                        );
                      })}
                    </Form.Select>
                    <div>
                      <div className='text-center m-t-40 m-b-20 p-relative'>
                        <p className='color-gray fs-12 fw-500'>PROFILE TYPE</p>
                        <label className='react-switch disabled'>
                          <input
                            className='react-switch-checkbox'
                            type='checkbox'
                          />
                          <div className='react-switch-button' />
                          <div className='react-switch-labels'>
                            <span>Business</span>
                            <span className='disabled' onClick={switchPersonal}>
                              Personal
                            </span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </Form.Group>
                  <div className={styles.footer}>
                    <Button
                      variant='dark'
                      onClick={(e) => createBusiness()}
                      className='btn-block btn-rounded fw-700'
                      disabled={!name}
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </div>
            </>
          )}
        </Col>
      </Row>
    </motion.div>
  );
}
