import { Card, Col, Row } from 'react-bootstrap';

export const BillingDetails = ({
  billing,
  date,
  verifications,
  credits,
  showLoader
}) => {
  if (credits && billing?.total_transactions) {
    return (
      <>
        <Row>
          <Col md={{ span: 6 }} xs={{ span: 6 }}>
            <p className='mb-1 mt-5 fs-24 fw-700'>{credits ? credits : 0}</p>
            <p className='m0 fs-14'>Credits</p>
          </Col>
          <Col md={{ span: 6 }} xs={{ span: 6 }}>
            <p className='mb-1 mt-5 fs-24 fw-700'>
              $ {billing?.total_transactions ? billing?.total_transactions : 0}
            </p>
            <p className='m0 fs-14'>
              {billing?.total_transactions ? billing?.total_transactions : 0}{' '}
              Transactions
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 6 }} xs={{ span: 6 }}>
            <p className='mb-1 mt-5 fs-24 fw-700'>
              $ {verifications ? verifications * 1.5 : 0}
            </p>
            <p className='m0 fs-14'>{verifications} ID Verifications</p>
          </Col>
          <Col md={{ span: 6 }} xs={{ span: 6 }}>
            <p className='mb-1 mt-5 fs-24 fw-700'>
              $ {billing?.file_hosting ? billing?.file_hosting : 0}
            </p>
            <p className='m0 fs-14'>File Hosting</p>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 6 }} xs={{ span: 6 }}>
            <p className='mb-1 mt-5 fs-24 fw-700'>
              $ {billing?.total_amount ? billing?.total_amount : 0.0}
            </p>
            <p className='m0 fs-14'>Due date on {date}</p>
          </Col>
        </Row>
      </>
    );
  }

  if (credits) {
    return (
      <>
        <Row>
          <Col md={{ span: 6 }} xs={{ span: 6 }}>
            <p className='mb-1 mt-5 fs-24 fw-700'>{credits ? credits : 0}</p>
            <p className='m0 fs-14'>{credits ? credits : 0} Credits</p>
          </Col>
          <Col md={{ span: 6 }} xs={{ span: 6 }}>
            <p className='mb-1 mt-5 fs-24 fw-700'>
              $ {billing?.file_hosting ? billing?.file_hosting : 0}
            </p>
            <p className='m0 fs-14'>File Hosting</p>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 6 }} xs={{ span: 6 }}>
            <p className='mb-1 mt-5 fs-24 fw-700'>
              $ {verifications ? verifications * 1.5 : 0}
            </p>
            <p className='m0 fs-14'>{verifications} ID Verifications</p>
          </Col>
          <Col md={{ span: 6 }} xs={{ span: 6 }}>
            <p className='mb-1 mt-5 fs-24 fw-700'>
              $ {billing?.total_amount ? billing?.total_amount : 0.0}
            </p>
            <p className='m0 fs-14'>Due date on {date}</p>
          </Col>
        </Row>
      </>
    );
  }

  return (
    <>
      <Row>
        <Col md={{ span: 6 }} xs={{ span: 6 }}>
          <p className='mb-1 mt-5 fs-24 fw-700'>
            $ {billing?.total_transactions ? billing?.total_transactions : 0}
          </p>
          <p className='m0 fs-14'>
            {billing?.total_transactions ? billing?.total_transactions : 0}{' '}
            Transactions
          </p>
        </Col>
        <Col md={{ span: 6 }} xs={{ span: 6 }}>
          <p className='mb-1 mt-5 fs-24 fw-700'>
            $ {billing?.file_hosting ? billing?.file_hosting : 0}
          </p>
          <p className='m0 fs-14'>File Hosting</p>
        </Col>
      </Row>
      <Row>
        <Col md={{ span: 6 }} xs={{ span: 6 }}>
          <p className='mb-1 mt-5 fs-24 fw-700'>
            $ {verifications ? verifications * 1.5 : 0}
          </p>
          <p className='m0 fs-14'>{verifications} ID Verifications</p>
        </Col>
        <Col md={{ span: 6 }} xs={{ span: 6 }}>
          <p className='mb-1 mt-5 fs-24 fw-700'>
            $ {billing?.total_amount ? billing?.total_amount : 0.0}
          </p>
          <p className='m0 fs-14'>Due date on {date}</p>
        </Col>
      </Row>
    </>
  );
};
