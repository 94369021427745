import * as Sentry from '@sentry/react';
import CameraIcon from 'assets/images/camera-white.svg';
import faceOutlineImg from 'assets/images/face-outline.png';
import Fingerscan from 'assets/images/finger-scan.svg';
import Identification from 'assets/images/identification.svg';
import RefreshIcon from 'assets/images/refresh.svg';
import User from 'assets/images/user-icon.svg';
import WhiteChevronRightIcon from 'assets/images/white-chevron-right.svg';
import axios from 'axios';
import ViewAcceptAcknowledgements from 'components/Acknowledgements/ViewAcceptAcknowledgements';
import SpinnerAnimation from 'components/Spinners/SpinnerAnimation/SpinnerAnimation';
import AdditionalFields from 'components/shared/SidebarMenu/AdditionalFields';
import appContext from 'context/app-context';
import { motion } from 'framer-motion';
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Webcam from 'react-webcam';
import styles from './styles.module.css';

// eslint-disable-next-line no-unused-vars
const videoConstraints = {
  width: 640,
  height: 480,
  facingMode: 'user'
  // forceScreenshotSourceSize: 'true'
};

const Camera = (props) => {
  const {
    transaction,
    selfieVerified,
    setSingleSigning,
    setCurrentPage,
    documents,
    signatory,
    setSelfieVerified,
    unSignedDocuments,
    setProfilePage,
    user,
    idNumber,
    currentDocument,
    setManualVerification,
    email,
    setSelfie,
    signatoryDocuments,
    country
  } = useContext(appContext);
  const navigate = useNavigate();

  const webcamRef = useRef(null);
  const fieldRef = useRef();

  const [validatePassed, setValidatePassed] = useState(false);
  const [url, setUrl] = useState(null);
  const [hideUrl, setHideUrl] = useState(false);
  const [proceed, setProceed] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState('');
  // const [email, setEmail] = useState(user?.email);
  const [verifyMe, setVerifyMe] = useState(false);
  const [signFiles, setSignFiles] = useState(false);
  const [addUserToSign, setAddUserToSign] = useState(false);
  const [manual, setManual] = useState(false);
  const [attempts, setAttempts] = useState(0);
  const [retake, setRetake] = useState(false);
  const [retakePreview, setRetakePreview] = useState(null);

  // ACKNOWLEDGEMENTS
  const [userAcknowledgemets, setUserAcknowledgemets] = React.useState([]);
  const [showAcknowledgement, setShowAcknowledgement] = useState(false);
  const [showSavetime, setShowSavetime] = useState(false);
  const [signSingleFile, setSignSingleFile] = useState(false);
  const [selectedDocs, setSelectedDocs] = useState(documents);
  const [showAcknowledgementPopup, setShowAcknowledgementPopup] =
    useState(true);
  const [showAcceptSign, setShowAcceptSign] = useState(true);
  const [showSignLoader, setShowSignLoader] = useState(false);

  const [verifiedFirstName, setVerifiedFirstName] = useState('');
  const [verifiedLastName, setVerifiedLastName] = useState('');

  useEffect(() => {
    if (signFiles && userAcknowledgemets?.length == 0) {
      signDocument(signSingleFile);
    }
  }, [userAcknowledgemets, selectedDocs]);

  // useEffect(() => {
  //   let pageUrl = new URL(window.location.href);
  //   let params = new URLSearchParams(pageUrl.search);
  //   let signType = params.get('sign_type');

  //   if (signType == 'existing' || selfieVerified) {
  //     if (user?.verified) {
  //     }
  //   }
  //   console.log('verified? ...', selfieVerified, user?.verified);
  // }, [selfieVerified]);

  const capturePhoto = useCallback(async () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setRetakePreview(imageSrc);
    setUrl(imageSrc);
    setSelfie(imageSrc);

    // setSelfieVerified(false);
    setProceed(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webcamRef]);

  const proceedBtn = () => {
    setProceed(true);
    // setSelfieVerified(true);
    if (user?.verified) {
      verifySelfie(true);
    } else {
      setVerifyMe(true);
    }
  };

  const addingUser = () => {
    setAddUserToSign(true);
  };

  async function verifySelfie(verified = false) {
    setAttempts(attempts + 1);

    if (manual) {
      manualVerification();
    }
    setShowLoader(true);
    setError('');

    try {
      const foreign_account =
        country && country !== 'South Africa' ? true : false;

      const requestData = {
        image_url: url,
        id_number: idNumber ? idNumber : user?.id_number,
        transaction_id: transaction?.id,
        document: currentDocument,
        id: signatory?.id,
        verified: verified,
        cell: signatory ? signatory.cell : user?.cell,
        email: signatory ? signatory.email : user?.email,
        foreign_account
      };

      const { data } = await axios.post(`verify-selfie`, requestData);

      //  foreigner route
      if (foreign_account) {
        setShowLoader(false);
        setSelfieVerified(true);
        return;
      }

      sessionStorage.setItem('selfie_verified', true);
      if (data.success) {
        if (props.isProfile) {
          setProfilePage(0);
        } else {
          setShowLoader(false);
          if (!data.verification.selfie_verified) {
            setManualVerification(true);
            setManual(true);
            return;
          }
          setSelfieVerified(true);
          setHideUrl(true);
          setProceed((wasOpened) => !wasOpened);
        }
        setVerifiedFirstName(data?.verification?.response?.FirstName);
        setVerifiedLastName(data?.verification?.response?.LastName);
      } else {
        //  failed SC image check

        if (attempts < 1) {
          retakeSelfie();
          return;
        }

        setTimeout(() => {
          if (props.isProfile) {
            setProfilePage(0);
          } else {
            setShowLoader(false);
            if (!data.verification.selfie_verified) {
              setManualVerification(true);
              setManual(true);
              return;
            }
            setShowLoader(false);
            setSelfieVerified(true);
            setHideUrl(true);
          }
          setError('');
          setError('Selfie verification failed.');
          console.log('Selfie not verified');
          console.log(data);
        }, 2000);
      }
    } catch (e) {
      if (attempts < 1) {
        retakeSelfie();
        return;
      }

      setTimeout(() => {
        if (props.isProfile) {
          setProfilePage(0);
        } else {
          setShowLoader(false);
          setManual(true);
          setManualVerification(true);
          return;
        }
      }, 2000);
      Sentry.captureException(e);
      setError('Image verification failed.');
      console.log('Image not verified');
      console.log(e.response.data);
    }
  }

  async function signDocument(isSingle) {
    fieldRef.current.save();
    console.log('camera.js... is single ' + isSingle);
    setSignSingleFile(isSingle);
    setSignFiles(true);

    if (userAcknowledgemets?.length > 0) {
      setShowAcknowledgement(true);
      setShowSavetime(false);
      return;
    }

    setError('');
    let documentId = '';
    setShowLoader(true);
    if (isSingle) {
      documentId = `/${currentDocument.uuid}`;
    }
    try {
      const { data } = await axios.post(
        `transactions/${transaction.id}/complete-signatory${documentId}`,
        {
          email: email,
          documents: selectedDocs
        }
      );

      setShowLoader(false);
      if (data.success) {
        setCurrentPage(5);
        setSingleSigning(isSingle);
      }
    } catch (e) {
      setTimeout(() => {
        setShowLoader(false);
        setError('Something went wrong please try again later!');
      }, 2000);
      Sentry.captureException(e);
      console.log(e);
    }
  }

  async function manualVerification() {
    setCurrentPage(8);
  }

  function sign(isSingle) {
    setSignSingleFile(isSingle);

    let documentIndex = signatoryDocuments.findIndex(
      (document) => document.uuid === currentDocument.uuid
    );
    let document =
      documentIndex !== -1 ? signatoryDocuments[documentIndex] : null;
    if (userAcknowledgemets?.length > 0) {
      setShowAcknowledgement(true);
      setShowSavetime(false);
      return;
    }

    signDocument(isSingle);
  }

  const retakeSelfie = () => {
    setUrl(false);
    setRetake(true);
    setHideUrl(false);
    setProceed(false);
    setShowLoader(false);
  };

  function disregard(DocumentUuid) {
    // Remove the document with disregarded acknowledgements from the list to sign
    let documentsToSign = selectedDocs;
    const newDocumentsArr = documentsToSign.filter((object) => {
      return object.id !== DocumentUuid;
    });

    if (signSingleFile) {
      setSignFiles(false);
      setSelectedDocs([]);
      // navigate('/');
      setCurrentPage(1);
      return;
    } else {
      setSelectedDocs(newDocumentsArr);
    }

    // Check if there are any any documents left to sign still
    let count = 0;
    newDocumentsArr.forEach((doc) => {
      if (doc.signed == 0) {
        count++;
      }
    });

    // If all are disregarded, go to home page
    if (newDocumentsArr.length == 0 || count == 0) {
      setSignFiles(false);
      setCurrentPage(1);
      return;
    }
  }

  const handleAdditionalFieldValidation = (validationState) => {
    setValidatePassed(validationState);
  };

  return (
    <>
      {!url && !hideUrl && !proceed && (
        <>
          {retake && <div className={styles.overlay} />}
          {!retake ? (
            <>
              <p className='pt-2 fw-500 m0'>
                Position your face in the viewfinder and
                <br />
                take a selfie. Ensure good lighting.
              </p>
              <div className='m-auto mt-3'>
                <div className={styles.webcamContainer}>
                  <img
                    src={faceOutlineImg}
                    className={styles.faceOutlineImg}
                    alt='face-outline'
                  />
                  {hideUrl ? (
                    ''
                  ) : (
                    <div className={styles.webcamFlip}>
                      <Webcam
                        ref={webcamRef}
                        audio={false}
                        height={480}
                        width={640}
                        screenshotFormat='image/jpeg'
                        className={styles.webcam}
                        forceScreenshotSourceSize={true}
                        //height='100%'
                      />
                    </div>
                  )}

                  <div className={styles.cameraBtns}>
                    {showLoader ? (
                      <div className='mb-3'>
                        <SpinnerAnimation />
                      </div>
                    ) : (
                      <Button
                        variant='dark'
                        className='rounded-circle btn-lg-rounded p0 pulse-animate'
                        onClick={() => {
                          capturePhoto();
                          setSelfieVerified(false);
                        }}
                      >
                        <img
                          src={CameraIcon}
                          className={styles.CameraIcon}
                          alt='selfie'
                        />
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) : (
            <img src={retakePreview} className={styles.photo} alt='Selfie' />
          )}

          {retake && (
            <Row className='m0 pt-4 pb-4 zindex-10'>
              <Col md={{ span: 6, offset: 3 }} xs={{ span: 12, offset: 0 }}>
                <div className={styles.footerRetake}>
                  <div>
                    <Row className='m0 pt-5 pb-4'>
                      <img
                        src={retakePreview}
                        className={styles.retakePreview}
                        alt='selfie'
                      />
                      <p className='fw-500 fs-14 pb-4'>
                        We could not verify your identity with this
                        <br />
                        selfie photo, please retake
                      </p>
                      <Col
                        md={{ span: 6, offset: 3 }}
                        xs={{ span: 12, offset: 0 }}
                      >
                        {showLoader ? (
                          <div className='p-b-50'>
                            <SpinnerAnimation />
                          </div>
                        ) : (
                          <Button
                            variant='dark'
                            className='btn-rounded btn-block mb-4 fw-700'
                            onClick={(e) => setRetake(false)}
                          >
                            Retake
                          </Button>
                        )}
                      </Col>
                      <Col xs={{ span: 12 }}>
                        <div className='text-center'>
                          <span
                            className='fs-16 fw-700 pointer fw-700'
                            onClick={(e) => setRetake(false)}
                          >
                            Cancel
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </>
      )}

      {url && !selfieVerified && (
        <div className={styles.capturedPhotoContainer}>
          {verifyMe ? <div className={styles.overlay}></div> : null}
          <img src={url} className={styles.photo} alt='Selfie' />
          <p className='m-t-15 fw-700 fs-16'>
            Your selfie remains private & is only used
            <br />
            for the purpose of identifying yourself.
          </p>
          <div>
            <p className={styles.error}>{error ? error : ''}</p>
            {showLoader ? (
              <div className='p-b-50'>
                <SpinnerAnimation />
              </div>
            ) : (
              <>
                <Row>
                  <Col
                    lg={{ span: 4, offset: 4 }}
                    md={{ span: 8, offset: 2 }}
                    xs={{ span: 12, offset: 0 }}
                    className={`${styles.continueOrRetake}`}
                  >
                    <Col>
                      <Button
                        variant='dark'
                        className='btn-block btn-rounded fw-700'
                        onClick={proceedBtn}
                      >
                        Continue
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        variant='outline-dark'
                        className='btn-block btn-rounded fw-700 m-t-15 btn-no-hover-change'
                        onClick={() => setUrl(null)}
                      >
                        Retake Selfie
                      </Button>
                    </Col>
                  </Col>
                </Row>
              </>
            )}
          </div>

          {verifyMe ? (
            <Row className='m0 pt-4 pb-4'>
              <Col md={{ span: 6, offset: 3 }} xs={{ span: 12, offset: 0 }}>
                <div className={styles.footer}>
                  <div className={styles.fingerscan}>
                    <img
                      src={Fingerscan}
                      alt='scan'
                      className={styles.fingerscanIcon}
                    />
                  </div>
                  {manual ? (
                    <>
                      <p className={`fw-500 fs-14 pb-3 ${styles.identity}`}>
                        Identity not verified
                      </p>
                      <br />
                      <p className='fw-500 fs-14 pb-3'>
                        Your identity could not be verified against
                        <br />
                        your ID photo at the Department of Home Affairs.
                        <br />
                        <br />
                        A photo of your ID card, Passport
                        <br />
                        or Drivers Licence is required.
                      </p>
                    </>
                  ) : (
                    <>
                      {country !== 'South Africa' ? (
                        <>
                          <p className='fw-500 fs-16 pb-3'>
                            To verify and protect your identity,
                            <br />
                            you consent that <strong>dewly</strong> can process
                            <br />
                            your Identity credentials against
                            <br />
                            the photo of your identity documents
                          </p>
                        </>
                      ) : (
                        <>
                          <p className='fw-500 fs-16 pb-3'>
                            To verify and protect your identity,
                            <br />
                            you consent that <strong>dewly</strong> can process
                            <br />
                            your Identity credentials against
                            <br />
                            the photo of your identity documents
                          </p>
                        </>
                      )}
                    </>
                  )}
                  <br />
                  <div>
                    <Row className='m0 pt-4 pb-4'>
                      <Col xs={{ span: 12, offset: 0 }}>
                        {showLoader ? (
                          <div className='p-b-24'>
                            <SpinnerAnimation />
                          </div>
                        ) : (
                          <Button
                            variant='dark'
                            className='btn-rounded btn-block mb-4 fw-700'
                            onClick={(e) => verifySelfie(false)}
                          >
                            {manual ? 'Continue' : 'Verify me'}
                          </Button>
                        )}
                      </Col>
                      <Col xs={{ span: 12 }}>
                        <div className='text-center'>
                          <span
                            className='fs-16 fw-700 pointer'
                            onClick={(e) => {
                              setVerifyMe(false);
                              retakeSelfie(false);
                              setRetake(false);
                            }}
                          >
                            Cancel
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </div>
      )}

      {selfieVerified && url && (
        <div className={styles.capturedPhotoContainer}>
          {unSignedDocuments > 1 ? (
            <>
              <motion.div
                initial={{ x: 0, y: 0, opacity: 0 }}
                animate={{ x: 0, y: 0, opacity: 1 }}
                transition={{ type: 'tween', delay: 0.3 }}
              >
                {signFiles && !showLoader && (
                  <div className={styles.overlay}></div>
                )}
                <div className={styles.flipImage}>
                  {url ? (
                    <img
                      src={url}
                      className={styles.photoPreview}
                      alt='Screenshot'
                    />
                  ) : (
                    ''
                  )}
                </div>
                <p className='fw-800 fs-18'>
                  Hi{' '}
                  {signatory ? (
                    <>
                      {signatory?.first_name} {signatory?.last_name}
                    </>
                  ) : (
                    <span className='lowercase'>{signatory?.email}</span>
                  )}
                </p>
                <AdditionalFields
                  validate={setValidatePassed}
                  ref={fieldRef}
                  documents={documents}
                  transaction={transaction}
                  user={signatory}
                />
                <p className='mt-5 fw-500 fs-14'>
                  By signing,
                  <br />
                  you agree to, & accept
                  <br />
                  the terms & conditions of
                </p>
                <div className='p-b-150'>
                  {selectedDocs.map((document, i) => {
                    return (
                      <p key={i} className='mb-2 fs-14 fw-700'>
                        {document.title}
                        <br />
                      </p>
                    );
                  })}
                </div>
                {showSignLoader && (
                  <div className='p-b-50'>
                    <SpinnerAnimation />
                  </div>
                )}
                {showAcceptSign && (
                  <div className={`${styles.footerWhite}`}>
                    <Row className='m0 pt-4'>
                      <Col
                        lg={{ span: 4, offset: 4 }}
                        md={{ span: 8, offset: 2 }}
                        xs={{ span: 12, offset: 0 }}
                        className={`${styles.footerInner}`}
                      >
                        {showLoader ? (
                          <div className='p-b-50'>
                            <SpinnerAnimation />
                          </div>
                        ) : (
                          <Button
                            variant='dark'
                            className='btn-rounded btn-block'
                            disabled={!validatePassed}
                            onClick={() => (
                              setSignFiles(true),
                              setShowSavetime(true),
                              fieldRef.current.save()
                            )}
                          >
                            <span className='fs-16 fw-700'>Accept & Sign</span>
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </div>
                )}

                {signFiles && showSavetime && (
                  <Row>
                    <Col
                      lg={{ span: 6, offset: 3 }}
                      md={{ span: 8, offset: 2 }}
                      xs={{ span: 12, offset: 0 }}
                    >
                      <div className={styles.footer}>
                        <p className='fw-800 fs-18 m0 pt-5'>
                          save time by signing
                          <br />
                          all files in one go?
                        </p>

                        {showLoader ? (
                          <div className='p-b-50'>
                            <SpinnerAnimation />
                          </div>
                        ) : (
                          <div className={styles.photoBtns}>
                            {error ? (
                              <p className={styles.error}>{error}</p>
                            ) : (
                              ''
                            )}
                            <Row className='m0 pt-4'>
                              <Col
                                md={{ span: 6 }}
                                xs={{ span: 12, offset: 0 }}
                                className='mobile-order-1'
                              >
                                <Button
                                  variant='outline-dark'
                                  className='btn-rounded btn-block mb-3'
                                  onClick={() => {
                                    setShowAcknowledgementPopup(false);
                                    setTimeout(() => {
                                      setShowAcknowledgementPopup(true);
                                    }, 500);
                                    sign(true);
                                    setShowAcceptSign(false);
                                    setShowSignLoader(true);
                                  }}
                                >
                                  <span className='fs-16 fw-700'>
                                    Sign individually
                                  </span>
                                </Button>
                              </Col>
                              <Col
                                md={{ span: 6 }}
                                xs={{ span: 12, offset: 0 }}
                              >
                                <Button
                                  variant='dark'
                                  className='btn-rounded btn-block mb-3'
                                  onClick={() => sign(false)}
                                >
                                  <span className='fs-16 fw-700'>Sign all</span>
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        )}
                      </div>
                    </Col>
                  </Row>
                )}
              </motion.div>
            </>
          ) : !selfieVerified ? (
            <>
              {!addUserToSign ? (
                <>
                  <Row className='m0'>
                    <Col
                      xs={{ span: 12, offset: 0 }}
                      className='bg-white min-height-full-page -m-t-56'
                    >
                      <div className='text-center mt-3 pt-5'>
                        <div className='pb-2 pt-4'>
                          <img
                            src={Identification}
                            alt='ID'
                            className={styles.icon}
                          />
                        </div>
                        <p className='fw-500 fs-14'>
                          Verification is temporarily unavailable
                        </p>
                        <p className='fw-700 fs-16'>*ID NUMBER GOES HERE*</p>
                        <Form.Group className='mb-4 ps-3 pe-3'>
                          <div className='pb-2 text-center'>
                            <label className={styles.label}>FIRST NAMES</label>
                          </div>
                          <Form.Control type='text' className={styles.input} />
                          {error ? (
                            <small className={styles.error}>{error}</small>
                          ) : (
                            ''
                          )}
                        </Form.Group>
                        <Form.Group className='mb-4 ps-3 pe-3'>
                          <div className='text-center pb-2'>
                            <label className={styles.label}>LAST NAME</label>
                          </div>
                          <Form.Control type='text' className={styles.input} />
                        </Form.Group>

                        <div className='text-center'>
                          <Button
                            variant='dark'
                            className='btn btn-dark rounded-circle btn-md-rounded p0 pulse-animate'
                            onClick={addingUser}
                          >
                            <img src={WhiteChevronRightIcon} alt='proceed' />
                          </Button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row className='m0'>
                    <Col
                      xs={{ span: 12, offset: 0 }}
                      className='bg-white min-height-full-page -m-t-70'
                    >
                      {signFiles && !showLoader && (
                        <div className={styles.overlay}></div>
                      )}
                      <div className='p-t-80 text-center'>
                        <img
                          src={url}
                          className={styles.photoPreview}
                          alt='Screenshot'
                        />
                        <p className='fw-800 fs-18 pb-4'>
                          <strong>Hello </strong>,
                        </p>
                        <p className='fw-700 fs-16'>Additional information</p>

                        <p className='mt-5 fw-500 fs-14'>
                          By signing this, you agree to, & accept
                          <br />
                          the terms & conditions of
                        </p>
                        <div className='p-b-100'>
                          {selectedDocs.map((document, i) => {
                            return (
                              <p key={i} className='mb-2 fs-14 fw-700'>
                                {document.title}
                                <br />
                              </p>
                            );
                          })}
                        </div>
                        <div className={styles.acceptSignFooter}>
                          <Row className='m0 pt-4 ps-3 pe-3'>
                            <Col
                              lg={{ span: 6, offset: 3 }}
                              md={{ span: 6, offset: 3 }}
                              xs={{ span: 12, offset: 0 }}
                            >
                              {showLoader ? (
                                <div className='p-b-50'>
                                  <SpinnerAnimation />
                                </div>
                              ) : (
                                <Button
                                  variant='dark'
                                  className='btn-rounded btn-block mb-3'
                                  onClick={() => (
                                    setSignFiles(true), setShowSavetime(true)
                                  )}
                                >
                                  <span className='fs-16 fw-700'>
                                    Accept & Sign
                                  </span>
                                </Button>
                              )}
                            </Col>
                          </Row>
                        </div>
                      </div>

                      {signFiles && showSavetime && (
                        <>
                          <Row>
                            <Col
                              lg={{ span: 6, offset: 3 }}
                              md={{ span: 8, offset: 2 }}
                              xs={{ span: 12, offset: 0 }}
                            >
                              <div className={styles.footer}>
                                <p className='fw-800 fs-18 m0 pt-5'>
                                  save time by signing
                                  <br />
                                  all files in one go?
                                </p>

                                {showLoader ? (
                                  <div className='p-b-50'>
                                    <SpinnerAnimation />
                                  </div>
                                ) : (
                                  <div className={styles.photoBtns}>
                                    {error ? (
                                      <p className={styles.error}>{error}</p>
                                    ) : (
                                      ''
                                    )}
                                    <Row className='m0 pt-4'>
                                      <Col
                                        md={{ span: 6 }}
                                        xs={{ span: 12, offset: 0 }}
                                        className='mobile-order-1'
                                      >
                                        <Button
                                          variant='outline-dark'
                                          className='btn-rounded btn-block mb-3'
                                          onClick={() => sign(true)}
                                        >
                                          <span className='fs-16 fw-700'>
                                            Sign individually
                                          </span>
                                        </Button>
                                      </Col>
                                      <Col
                                        md={{ span: 6 }}
                                        xs={{ span: 12, offset: 0 }}
                                      >
                                        <Button
                                          variant='dark'
                                          className='btn-rounded btn-block mb-3'
                                          onClick={() => sign(false)}
                                        >
                                          <span className='fs-16 fw-700'>
                                            Sign all
                                          </span>
                                        </Button>
                                      </Col>
                                    </Row>
                                  </div>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </>
                      )}
                    </Col>
                  </Row>
                </>
              )}
            </>
          ) : (
            <>
              <div className='p-t-200'>
                <motion.div
                  initial={{ x: 0, y: 0, opacity: 0 }}
                  animate={{ x: 0, y: -170, opacity: 1 }}
                  transition={{ type: 'tween', delay: 0.3 }}
                >
                  <div className={styles.flipImage}>
                    {url ? (
                      <img
                        src={url}
                        className={styles.photoPreview}
                        alt='Screenshot'
                      />
                    ) : (
                      ''
                    )}
                  </div>
                  <p className='fw-800 fs-18'>
                    Hello,{' '}
                    {signatory && signatory.first_name ? (
                      <>
                        {signatory?.first_name}{' '}
                        {signatory.last_name && <>{signatory?.last_name}</>}
                      </>
                    ) : verifiedFirstName && verifiedLastName ? (
                      verifiedFirstName + ' ' + verifiedLastName
                    ) : (
                      <span className='lowercase'>{signatory?.email}</span>
                    )}
                  </p>

                  <AdditionalFields
                    validate={setValidatePassed}
                    ref={fieldRef}
                    documents={documents}
                    transaction={transaction}
                    user={signatory}
                  />

                  <p className='mt-5 fw-500 fs-14'>
                    By signing this, you agree to, & accept
                    <br />
                    the terms & conditions of
                    {selectedDocs.map((document, i) => {
                      return (
                        <p key={i} className='mb-1 fs-14 fw-700'>
                          <br />
                          {document.title}
                          <br />
                        </p>
                      );
                    })}
                  </p>
                </motion.div>

                {error ? <p className={styles.error}>{error}</p> : ''}
                <div className={`${styles.footerWhite}`}>
                  <Row className='m0 pt-4'>
                    <Col
                      lg={{ span: 4, offset: 4 }}
                      md={{ span: 8, offset: 2 }}
                      xs={{ span: 12, offset: 0 }}
                      className={`${styles.footerInner}`}
                    >
                      {showLoader ? (
                        <div className='p-b-50'>
                          <SpinnerAnimation />
                        </div>
                      ) : (
                        <Button
                          variant='dark'
                          className='btn-rounded btn-block'
                          onClick={() => signDocument(false)}
                          disabled={!validatePassed}
                          // onClick={() => sign(false)}
                        >
                          <span className='fs-16 fw-700'>Accept & Sign</span>
                        </Button>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </>
          )}
        </div>
      )}

      {showAcknowledgementPopup && (
        <ViewAcceptAcknowledgements
          currentDocument={currentDocument}
          document={document}
          transaction={transaction}
          signatory={signatory}
          setUserAcknowledgemets={setUserAcknowledgemets}
          showAcknowledgement={showAcknowledgement}
          generatePDFafterAccept={false}
          signSingleFile={signSingleFile}
          disregard={disregard}
        />
      )}
    </>
  );
};

export default Camera;
