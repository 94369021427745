import * as Sentry from '@sentry/react';
import axios from 'axios';
import GoBackHeader from 'components/GoBackHeader/GoBackHeader';
import SpinnerAnimation from 'components/Spinners/SpinnerAnimation/SpinnerAnimation';
import appContext from 'context/app-context';
import { motion } from 'framer-motion';
import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import styles from './styles.module.css';

export default function ChangePassword() {
  const { setProfilePage, user } = useContext(appContext);
  const [password, setPassword] = useState('');
  const [confirm, setConfirm] = useState('');
  const [showLoader, setShowLoader] = useState(false);
  const [error, setError] = useState(null);
  const passwordInput = useRef();

  useEffect(() => {
    if (passwordInput.current) passwordInput.current.focus();
  }, [passwordInput]);

  function previousPage() {
    setProfilePage(0);
  }

  const changePassword = async () => {
    try {
      setShowLoader(true);
      const { data } = await axios.post(`/users/${user.id}/change-password`, {
        password: password,
        password_confirmation: confirm
      });

      if (data.success) {
        setProfilePage(0);
      }
      setShowLoader(false);
    } catch (e) {
      setError(e.response.data.errors.password[0]);
      setShowLoader(false);
      console.log(e);
      Sentry.captureException(e);
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <Row className='m0'>
        <Col
          lg={{ span: 4, offset: 4 }}
          md={{ span: 6, offset: 3 }}
          className='bg-white min-height-full-page'
        >
          <GoBackHeader previousPage={previousPage} pageName={'New Password'} />

          <Form className={styles.form}>
            <Form.Group className='mb-4 ps-3 pe-3'>
              <div className='pb-2 text-center'>
                <label className={styles.label}>PASSWORD</label>
              </div>
              <Form.Control
                type='password'
                ref={passwordInput}
                onChange={(e) => setPassword(e.currentTarget.value)}
                className={styles.input}
              />
              {error ? <small className={styles.error}>{error}</small> : ''}
            </Form.Group>
            <Form.Group className='mb-4 ps-3 pe-3'>
              <div className='text-center pb-2'>
                <label className={styles.label}>CONFIRM PASSWORD</label>
              </div>
              <Form.Control
                type='password'
                className={styles.input}
                onChange={(e) => setConfirm(e.currentTarget.value)}
              />
            </Form.Group>

            <Row className='m0'>
              <Col
                lg={{ span: 4, offset: 4 }}
                md={{ span: 6, offset: 3 }}
                className={styles.footer}
              >
                {showLoader ? (
                  <div className='h-54 p-relative'>
                    <SpinnerAnimation />
                  </div>
                ) : (
                  <Button
                    variant='dark'
                    disabled={password !== confirm || !password}
                    className='btn-block btn-rounded fw-700'
                    onClick={(e) => changePassword()}
                  >
                    Save
                  </Button>
                )}
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </motion.div>
  );
}
